import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of, throwError } from "rxjs";
import { catchError, filter, map, take, tap } from "rxjs/operators";

import { environment } from "../../../environments/environment";
import {ApiService} from '../api.service'
import {StorageService} from '@services/storage.service'

/** donnée d'authentification type */
export type LoginData = { username: string, password: string }
/** donnée utilisateur type */
export type User = {
  refreshToken?: string,
  id?: number,
  username?: string,
  firstname?: string,
  lastname?: string,
  email?: string,
  roles?: string[],
  team?: {
    id?: number,
    groupName?: string
  },
  accessToken: string,
  tokenType: string
}

/**
 * Service gérant l'authentification
 */
@Injectable()
export class AuthentificationService {
  // url de l'api
  protected url = environment.auth
  protected urlLogin = this.url + '/signin'
  protected urlRefreshToken = this.url + '/refreshtoken'
  // données user
  protected readonly _defaultUser: User = {accessToken: '', email: '', id: 0, refreshToken: '', roles: [], team: {}, tokenType: '', username: '', firstname: '', lastname: ''}
  protected readonly _user: BehaviorSubject<User> = new BehaviorSubject<User>(this._defaultUser)
  protected _expired: number = 0
  protected _timerExpired: any

  /**
   * gère la valeur d'expiration du token en deux temps.
   * si l'utilisateur est toujours actif sur l'application, un setInterval va provoquer le rafraichissement du token
   * si l'utilisateur n'est plus actif et que le timer est arrivé a expiration, il provoquera une déconnexion
   * @param exp
   * @protected
   */
  protected set expired(exp: number | undefined) {
    this._expired = exp ?? 0
    if (exp) {
      clearInterval(this._timerExpired)
      this._timerExpired = setInterval(() => this.refresh(), this._expired - Date.now())
    } else {
      this.logout()
    }
  }

  /** retourne l'utilisateur courent */
  get user() {
    return this._user.value
  }

  /**
   * gère la valeur user et la réécrit selon le contenu de l'accessToken
   * afin de garder les valeurs réel à la lecture de l'user
   * @param {User} user
   */
  set user(user: User) {
    this._user.next(user)
    const jwtDecoded = this.decode()
    this.expired = jwtDecoded?.exp * 1000
    user.username = jwtDecoded?.sub
    user.roles = jwtDecoded?.roles
    user.team = {
      id: jwtDecoded?.teamId
    }
    this._user.next(user)
  }

  /**
   * retourne l'état de connexion qui dépend de l'accessToken
   */
  get isLogged(): boolean {
    return this.user.accessToken != ''
  }

  constructor(private apiService: ApiService, public http: HttpClient, public storage: StorageService) {
  }

  /** initialise une authentification */
  post(data: LoginData): Observable<any> {
    return this.http.post<User>(this.urlLogin, data).pipe(
      take(1),
      catchError(this.apiService.handleError),
      map((v: User) => {
        this.user = Object.assign(this._defaultUser, v) as User
        this.storage.set('user', this.user)
      })
    )
  }

  /** charge l'utilisateur du storage */
  loadUser(): Observable<User> {
    this.user = Object.assign(this._defaultUser, this.storage.get('user')) as User
    return of(this.user)
  }

  /**
   * déchiffrement du token jwt
   * @private
   */
  private decode() {
    if (!this.user?.accessToken) {
      return
    }
    const base64Url = this.user.accessToken.split('.')[1]
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)).join(''))

    return JSON.parse(jsonPayload)
  }

  /** déconnexion de l'utilisateur */
  logout() {
    clearInterval(this._timerExpired)
    this._user.next({accessToken: '', email: '', id: 0, refreshToken: '', roles: [], team: {}, tokenType: '', username: ''})
    this.storage.set('user', this.user)
  }

  /**
   * Permet de rafraichir le token utilisateur
   */
  private refresh() {
    this.http.post<User>(this.urlRefreshToken, {refreshToken: this.user?.refreshToken}).pipe(
      take(1),
      catchError(this.apiService.handleError)
    ).subscribe({
      next: (v: User) => {
        // fusion de l'objet user avec la valeur par default pour éliminer toutes erreurs dans la manipulation de l'User
        this.user = Object.assign(this._defaultUser, v) as User
        this.storage.set('user', this.user)
      },
      error: () => {
        this.logout()
      }
    })
    return this._user
  }
}

/**
 * Provider pour charger les données utilisateur au chargement de l'application
 */
export function AuthProviderFactory(provider: AuthentificationService) {
  return () => {
    return new Promise((resolve, reject) => {
      provider.loadUser().pipe(take(1)).subscribe({
        next: _ => {
          resolve(provider.user)
        },
        error: _ => {
          reject({name: 'User', status: 403, msg: 'Unauthorized'})
        }
      })
    })
  }
}
