import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';

import { InputBaseComponent } from '../input-base.component';

@Component({
  selector: 'dsfr-input-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckboxComponent extends InputBaseComponent {

  @Input() defaultValue: any;

  typeOf(value: any) {
    return typeof value;
  }

}
