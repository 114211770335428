import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { AttestationRoutingModule } from './attestation-routing.module';
import { SharedModule } from '../shared/shared.module';
import { DsmjModalModule } from '@mjfr/ng-dsmj';
import { CheckboxModule } from '@shared/dsfr/checkbox.module';
import { RadioModule } from '@shared/dsfr/radio.module';
import { SelectModule } from '@shared/dsfr/select.module';
import { TextModule } from '@shared/dsfr/text.module';
import { NumberModule } from '@shared/dsfr/number.module';
import { ModalModule } from '@shared/dsfr/modal.module';
import { PaginationModule } from '@shared/dsfr/paginaion.module';
import { CreateComponent } from '../attestation/component/create/create.component';
import { ListComponent } from '../attestation/component/list/list.component';
import { AttestationComponent } from '../attestation/component/attestation/attestation.component';
import { RecapComponent } from '../attestation/component/recap/recap.component';
import { EditComponent } from '../attestation/component/create/edit.component';
import { JusticiableComponent } from './component/justiciable/justiciable.component';
import { AffaireComponent } from './component/affaire/affaire.component';
import { AuxiliaireComponent } from './component/auxiliaire/auxiliaire.component';
import { MissionComponent } from './component/mission/mission.component';

@NgModule({
  declarations: [
    CreateComponent,
    EditComponent,
    ListComponent,
    AttestationComponent,
    RecapComponent,
    JusticiableComponent,
    AffaireComponent,
    AuxiliaireComponent,
    MissionComponent
  ],
  imports: [
    CommonModule,
    AttestationRoutingModule,
    FormsModule,
    SharedModule,
    DsmjModalModule,
    CheckboxModule,
    RadioModule,
    SelectModule,
    TextModule,
    NumberModule,
    ModalModule,
    PaginationModule
  ],
  exports: [FormsModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AttestationModule {}
