import { LOCALE_ID, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import * as fr from '@angular/common/locales/fr';
import { DsmjHeaderModule, DsmjFooterModule, DsmjNavModule, DsmjStepperModule } from '@mjfr/ng-dsmj';
import { HeaderComponent } from './component/header/header.component';
import { FooterComponent } from './component/footer/footer.component';
import { SkiplinksComponent } from './component/skiplinks/skiplinks.component';
import { AuthentificationService } from '../../services/authentification/authentification.service';

@NgModule({
  declarations: [HeaderComponent, FooterComponent, SkiplinksComponent],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule,
    HttpClientModule,
    DsmjHeaderModule,
    DsmjFooterModule,
    DsmjStepperModule,
    DsmjNavModule
  ],
  providers: [
    AuthentificationService,
    { provide: LOCALE_ID, useValue: 'fr-FR'},
  ],
  exports: [RouterModule, HeaderComponent, FooterComponent, SkiplinksComponent]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    registerLocaleData(fr.default);
    if (parentModule) {
      throw new Error(`CoreModule has already been loaded. Import Core
      modules in the AppModule only.`);
    }
  }
}
