export enum Barreau {
"AGEN" ="AGEN",
"AIN" ="AIN",
"AIX-EN-PROVENCE" ="AIX-EN-PROVENCE",
"AJACCIO" ="AJACCIO",
"ALBERTVILLE" ="ALBERTVILLE",
"ALBI" ="ALBI",
"ALENÇON" ="ALENÇON",
"ALÈS" ="ALÈS",
"ALPES DE HAUTE-PROVENCE (DIGNE)" ="ALPES DE HAUTE-PROVENCE (DIGNE)",
"AMIENS" ="AMIENS",
"ANGERS" ="ANGERS",
"ANNECY" ="ANNECY",
"ARDECHE" ="ARDECHE",
"ARDENNES (CHARLEVILLE-MÉZIÈRES)" ="ARDENNES (CHARLEVILLE-MÉZIÈRES)",
"ARGENTAN" ="ARGENTAN",
"ARIEGE" ="ARIEGE",
"ARRAS" ="ARRAS",
"AUBE (TROYES)" ="AUBE (TROYES)",
"AURILLAC" ="AURILLAC",
"AUXERRE" ="AUXERRE",
"AVESNES-SUR-HELPE" ="AVESNES-SUR-HELPE",
"AVEYRON" ="AVEYRON",
"AVIGNON" ="AVIGNON",
"BASTIA" ="BASTIA",
"BAYONNE" ="BAYONNE",
"BEAUVAIS" ="BEAUVAIS",
"BELFORT" ="BELFORT",
"BERGERAC" ="BERGERAC",
"BESANÇON" ="BESANÇON",
"BÉTHUNE" ="BÉTHUNE",
"BÉZIERS" ="BÉZIERS",
"BLOIS" ="BLOIS",
"BONNEVILLE" ="BONNEVILLE",
"BORDEAUX" ="BORDEAUX",
"BOULOGNE-SUR-MER" ="BOULOGNE-SUR-MER",
"BOURGES" ="BOURGES",
"BOURGOIN-JALLIEU" ="BOURGOIN-JALLIEU",
"BREST" ="BREST",
"BRIEY" ="BRIEY",
"BRIVE" ="BRIVE",
"CAEN" ="CAEN",
"CAMBRAI" ="CAMBRAI",
"CARCASSONNE" ="CARCASSONNE",
"CARPENTRAS" ="CARPENTRAS",
"CASTRES" ="CASTRES",
"CHALONS-EN-CHAMPAGNE" ="CHALONS-EN-CHAMPAGNE",
"CHALON-SUR-SAÔNE" ="CHALON-SUR-SAÔNE",
"CHAMBÉRY" ="CHAMBÉRY",
"CHARENTE (ANGOULÊME)" ="CHARENTE (ANGOULÊME)",
"CHARTRES" ="CHARTRES",
"CHÂTEAUROUX" ="CHÂTEAUROUX",
"CHERBOURG" ="CHERBOURG",
"CLERMONT-FERRAND" ="CLERMONT-FERRAND",
"COLMAR" ="COLMAR",
"COMPIÈGNE" ="COMPIÈGNE",
"COUTANCES-AVRANCHES" ="COUTANCES-AVRANCHES",
"CREUSE (GUÉRET)" ="CREUSE (GUÉRET)",
"CUSSET/VICHY" ="CUSSET/VICHY",
"DAX" ="DAX",
"DEUX-SEVRES" ="DEUX-SEVRES",
"DIEPPE" ="DIEPPE",
"DIJON" ="DIJON",
"DOUAI" ="DOUAI",
"DRAGUIGNAN" ="DRAGUIGNAN",
"DROME" ="DROME",
"DUNKERQUE" ="DUNKERQUE",
"ÉPINAL" ="ÉPINAL",
"ESSONNE" ="ESSONNE",
"EURE" ="EURE",
"FONTAINEBLEAU" ="FONTAINEBLEAU",
"GERS" ="GERS",
"GRASSE" ="GRASSE",
"GRENOBLE" ="GRENOBLE",
"GUADELOUPE/SAINT-MARTIN/SAINT-BARTHÉLEMY" ="GUADELOUPE/SAINT-MARTIN/SAINT-BARTHÉLEMY",
"GUYANE (CAYENNE)" ="GUYANE (CAYENNE)",
"HAUTE-LOIRE (LE PUY-EN-VELAY)" ="HAUTE-LOIRE (LE PUY-EN-VELAY)",
"HAUTE-MARNE" ="HAUTE-MARNE",
"HAUTES-ALPES" ="HAUTES-ALPES",
"HAUTE-SAÔNE (VESOUL)" ="HAUTE-SAÔNE (VESOUL)",
"HAUTS-DE-SEINE" ="HAUTS-DE-SEINE",
"JURA (LONS-LE-SAUNIER)" ="JURA (LONS-LE-SAUNIER)",
"LA ROCHELLE-ROCHEFORT" ="LA ROCHELLE-ROCHEFORT",
"LA ROCHE-SUR-YON" ="LA ROCHE-SUR-YON",
"LAON" ="LAON",
"LAVAL" ="LAVAL",
"LE HAVRE" ="LE HAVRE",
"LE MANS" ="LE MANS",
"LES SABLES D'OLONNE" ="LES SABLES D'OLONNE",
"LIBOURNE" ="LIBOURNE",
"LILLE" ="LILLE",
"LIMOGES" ="LIMOGES",
"LISIEUX" ="LISIEUX",
"LORIENT" ="LORIENT",
"LOT (CAHORS)" ="LOT (CAHORS)",
"LOZERE" ="LOZERE",
"LYON" ="LYON",
"MÂCON/CHAROLLES" ="MÂCON/CHAROLLES",
"MARSEILLE" ="MARSEILLE",
"MARTINIQUE (FORT-DE-FRANCE)" ="MARTINIQUE (FORT-DE-FRANCE)",
"MAYOTTE (MAMOUDZOU)" ="MAYOTTE (MAMOUDZOU)",
"MEAUX" ="MEAUX",
"MELUN" ="MELUN",
"METZ" ="METZ",
"MEUSE (BAR-LE-DUC/VERDUN)" ="MEUSE (BAR-LE-DUC/VERDUN)",
"MONTARGIS" ="MONTARGIS",
"MONTBÉLIARD" ="MONTBÉLIARD",
"MONT-DE-MARSAN" ="MONT-DE-MARSAN",
"MONTLUÇON" ="MONTLUÇON",
"MONTPELLIER" ="MONTPELLIER",
"MOULINS" ="MOULINS",
"MULHOUSE" ="MULHOUSE",
"NANCY" ="NANCY",
"NANTES" ="NANTES",
"NARBONNE" ="NARBONNE",
"NEVERS" ="NEVERS",
"NICE" ="NICE",
"NÎMES" ="NÎMES",
"NOUMÉA" ="NOUMÉA",
"ORLÉANS" ="ORLÉANS",
"PAPEETE" ="PAPEETE",
"PARIS" ="PARIS",
"PAU" ="PAU",
"PÉRIGUEUX" ="PÉRIGUEUX",
"POITIERS" ="POITIERS",
"PYRENEES-ORIENTALES" ="PYRENEES-ORIENTALES",
"QUIMPER" ="QUIMPER",
"REIMS" ="REIMS",
"RENNES" ="RENNES",
"ROANNE" ="ROANNE",
"ROUEN" ="ROUEN",
"SAINT MALO-DINAN" ="SAINT MALO-DINAN",
"SAINT-BRIEUC" ="SAINT-BRIEUC",
"SAINT-DENIS-DE-LA-RÉUNION" ="SAINT-DENIS-DE-LA-RÉUNION",
"SAINTES" ="SAINTES",
"SAINT-ÉTIENNE" ="SAINT-ÉTIENNE",
"SAINT-GAUDENS" ="SAINT-GAUDENS",
"SAINT-NAZAIRE" ="SAINT-NAZAIRE",
"SAINT-OMER" ="SAINT-OMER",
"SAINT-PIERRE-DE-LA-RÉUNION" ="SAINT-PIERRE-DE-LA-RÉUNION",
"SAINT-QUENTIN" ="SAINT-QUENTIN",
"SARREGUEMINES" ="SARREGUEMINES",
"SAUMUR" ="SAUMUR",
"SAVERNE" ="SAVERNE",
"SEINE-SAINT-DENIS (BOBIGNY)" ="SEINE-SAINT-DENIS (BOBIGNY)",
"SENLIS" ="SENLIS",
"SENS" ="SENS",
"SOISSONS" ="SOISSONS",
"STRASBOURG" ="STRASBOURG",
"TARASCON" ="TARASCON",
"TARBES" ="TARBES",
"TARN & GARONNE" ="TARN & GARONNE",
"THIONVILLE" ="THIONVILLE",
"THONON-LES-BAINS, DU LEMAN ET DU GENEVOIS" ="THONON-LES-BAINS, DU LEMAN ET DU GENEVOIS",
"TOULON" ="TOULON",
"TOULOUSE" ="TOULOUSE",
"TOURS" ="TOURS",
"TULLE" ="TULLE",
"VAL D'OISE" ="VAL D'OISE",
"VAL-DE-MARNE (CRÉTEIL)" ="VAL-DE-MARNE (CRÉTEIL)",
"VALENCIENNES" ="VALENCIENNES",
"VANNES" ="VANNES",
"VERSAILLES" ="VERSAILLES",
"VIENNE" ="VIENNE",
"VILLEFRANCHE-SUR-SAÔNE" ="VILLEFRANCHE-SUR-SAÔNE",
  }