import { NgModule } from '@angular/core';
import { RouterModule, Routes, ExtraOptions } from '@angular/router';

import { LoginActivate } from "@services/LoginActivate.service";
import { HomeComponent } from "./modules/pages/home/home.component";

const routes: Routes = [
  { path: '', redirectTo: 'accueil', pathMatch: 'full' },
  {
    path: 'accueil',
    component: HomeComponent,
    canActivate: [LoginActivate],
    data: { breadcrumb: 'Accueil'}
  },
  {
    path: '',
    loadChildren: () => import('./modules/pages/pages.module').then(x => x.PagesModule)
  },
  {
    path: 'attestations',
    loadChildren: () => import('./modules/attestation/attestation.module').then(x => x.AttestationModule)
  }
];

const routerOptions: ExtraOptions = {
  useHash: false,
  anchorScrolling: 'enabled',
  scrollPositionRestoration: "enabled"
  // ...any other options you'd like to use
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
