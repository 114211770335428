import {HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest} from '@angular/common/http'
import {Injectable} from '@angular/core'
import {Observable} from 'rxjs'
import {AuthentificationService} from '../services/authentification/authentification.service'

/**
 * Intercepteur utilisé pour configurer automatiquement l'Authorization
 */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(protected auth: AuthentificationService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (this.auth.isLogged) {

      const headers = new HttpHeaders()
        .set('Authorization', this.auth.user?.tokenType + ' ' + this.auth.user?.accessToken);


      request = request.clone({
        withCredentials: true,
        headers: headers
      })
      return next.handle(request)
    }

    return next.handle(request)
  }
}
