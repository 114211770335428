import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { LoginActivate } from "../../services/LoginActivate.service";
import { ListComponent } from "./component/list/list.component";
import { CreateComponent } from "./component/create/create.component";
import { RecapComponent } from "./component/recap/recap.component";
import { EditComponent } from "./component/create/edit.component";


const routes: Routes = [
  {
    path: '',
    redirectTo: 'liste',
    pathMatch: 'full'
  },
  {
    path: 'liste',
    component: ListComponent,
    canActivate:[LoginActivate],
    data: { breadcrumb: 'Liste de attestations'}
  },
  {
    path: 'attestations',
    children: [
      {
        path: 'creation',
        component: CreateComponent,
        canActivate:[LoginActivate],
        data: { breadcrumb: 'Nouvelle attestation de mission'}
      },
      {
        path: 'synthese/:id',
        component: RecapComponent,
        canActivate:[LoginActivate],
        data: { breadcrumb: 'Synthèse de l\'attestation de mission'}
      },
      {
        path: 'edition/:id',
        component: EditComponent,
        canActivate:[LoginActivate],
        data: { breadcrumb: 'Edition de l\'attestation de mission'}
      }
    ],
    data: { breadcrumb: 'Attestation de mission'}
  }

];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AttestationRoutingModule {}
