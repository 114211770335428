import { Component,Output, OnInit, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { ControlContainer, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";

@Component({
  selector: 'form-create-affaire',
  templateUrl: './affaire.component.html',
  styleUrls: ['./affaire.component.scss']
})
export class AffaireComponent implements OnInit {


  /** FormGroup parent */
  parent: FormGroup;

  typeDecision: String ;

  isNonJuridictionnelle : boolean = true;

  isCivileOrPenale : boolean = false;

  @Output() returnValue = new EventEmitter<any>();

  aideJuridic = [{value: 'total', label: 'Totale'}, {value: 'partiel', label: 'Partielle'}];
  pourcentageAjList = [{value: '100', label: '100%'}, {value: '55', label: '55%'}, {value: '25', label: '25%'}];
  pourcentageAjTotal = [{value: '100', label: '100%'}];
  pourcentageAjPartiel = [{value: '55', label: '55%'}, {value: '25', label: '25%'}];
  personneAssistee = [{value: 'mineur', label: 'Mineure (m)'}, {value: 'majeur', label: 'Majeure (M)'}];

  typeAffaireList: {[key:string]:string} = {
    'Administrative': 'Administrative',
    'Civile': 'Civile',
    'Pénale': 'Pénale',
  };

  typeProcedureList: {[key:string]:string} = {
    'a': 'a',
    'b': 'b',
  };

  constructor(protected _parentContainer: ControlContainer, protected fb: FormBuilder) { }

  ngOnInit(): void {
    this.parent = this._parentContainer.control as FormGroup;
  }

  returnValues(event: String) {	
    this.typeDecision = event;
    if (event == "total") {
      this.pourcentageAj?.patchValue('100');
    } 
	}

  checkAffaireType() {
    if (this.typeAffaire?.value != "Administrative" && this.typeAffaire?.value != "Civile" && this.typeAffaire?.value != "Pénale") {
      this.isNonJuridictionnelle = true;
      this.numeroAffaire?.clearValidators();
      this.numeroAffaire?.reset();
      this.numeroAffaire?.updateValueAndValidity();
    }
    else { 
      this.isNonJuridictionnelle = false;
      this.numeroAffaire?.setValidators([Validators.required]);
      this.numeroAffaire?.updateValueAndValidity();
    }
    if (this.typeAffaire?.value == "Civile" || this.typeAffaire?.value == "Pénale") {
      this.isCivileOrPenale = true;
    }
    else { 
      this.isCivileOrPenale = false;
    }
  }

  get typeAffaire() {
    return this.affaire?.get('typeAffaire');
  }

  get affaire() {
    return this.parent?.get('affaire');
  }

  get numeroAffaire() {
    return this.affaire?.get('numeroAffaire');
  }

  get pourcentageAj() {
    return this.affaire?.get('pourcentageAj');
  }

  get aideJuridiction() {
    return this.affaire?.get('aideJuridic');
  }

  get dateBaj() {
    return this.affaire?.get('dateBaj');
  }

  get ajGarantie() {
    return this.affaire?.get('ajGarantie');
  }

  get dateBajGroup() {
    return (this.dateBaj?.touched || this.dateBaj?.dirty) && this.dateBaj?.errors ? 'error' : '';
  }

}
