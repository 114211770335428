import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthentificationService, User } from '@services/authentification/authentification.service';

/**
 * Composant gérant le header
 */
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {

  public user: User;

  constructor(private router: Router, public auth: AuthentificationService) {}

  ngOnInit(): void {
    this.auth.loadUser().subscribe({
      next: (user) => (this.user = user)
    });
  }


  /**
   * Déconnecte l'utilisateur et le redirige vers la page de connexion
   */
  logout(event: Event) {
    event.preventDefault();
    this.auth.logout()
    this.router.navigate(['connexion'])
  }
}
