import { ChangeDetectorRef, forwardRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, Provider } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { PagesModel } from './models';

export interface PageChangedEvent {
  itemsPerPage: number;
  page: number;
}

export const PAGINATION_CONTROL_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => PaginationComponent),
  multi: true
};

/**
 * Composant modale
 */
@Component({
  selector: 'dsfr-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
  providers: [PAGINATION_CONTROL_VALUE_ACCESSOR]
})
export class PaginationComponent implements ControlValueAccessor, OnInit {

  /** Si true le composant de pagination sera désactivé */
  @Input() disabled = false;

  /** Déclenché quand le nombre total de page change **/
  @Output() numPages = new EventEmitter<number>();

  /** Déclenché quand la page a été changé */
  @Output() pageChanged = new EventEmitter<PageChangedEvent>();
  onChange = Function.prototype;
  onTouched = Function.prototype;
  pages?: PagesModel[];
  protected initialized = false;

  constructor(private elementRef: ElementRef, private changeDetection: ChangeDetectorRef) {
    this.elementRef = elementRef;
  }

  protected _itemsPerPage = 10;

  /** Nombre maximum d’éléments par page. Si la valeur est inférieure à 1, affiche tous les éléments sur une page */
  @Input()
  get itemsPerPage(): number {
    return this._itemsPerPage;
  }

  set itemsPerPage(v: number) {
    this._itemsPerPage = v;
    this.totalPages = this.calculateTotalPages();
  }

  protected _totalItems = 0;

  /** Nombre total d’éléments dans toutes les pages */
  @Input()
  get totalItems(): number {
    return this._totalItems;
  }

  set totalItems(v: number) {
    this._totalItems = v;
    this.totalPages = this.calculateTotalPages();
  }

  /** Nombre total de pages */
  protected _totalPages = 0;

  get totalPages(): number {
    return this._totalPages;
  }

  set totalPages(v: number) {
    this._totalPages = v;
    this.numPages.emit(v);
    if (this.initialized) {
      this.selectPage(this.page);
    }
  }

  /** Page courrante */
  protected _page = 1;

  get page(): number {
    return this._page;
  }

  set page(value: number) {
    const _previous = this._page;
    this._page = value > this.totalPages ? this.totalPages : value || 1;
    this.changeDetection.markForCheck();

    if (_previous === this._page || typeof _previous === 'undefined') {
      return;
    }

    this.pageChanged.emit({
      page: this._page,
      itemsPerPage: this.itemsPerPage
    });
  }

  /** Inialise toutes les valeurs */
  ngOnInit(): void {
    // base class
    if (typeof this.itemsPerPage === 'undefined') {
      this.itemsPerPage = this._itemsPerPage || 0;
    }

    this.totalPages = this.calculateTotalPages();
    // this class
    this.pages = this.getPages(this.page, this.totalPages);

    this.initialized = true;
  }
  
  writeValue(value: number): void {
    this.page = value;
    this.pages = this.getPages(this.page, this.totalPages);
  }

  noPrevious(): boolean {
    return this.page === 1;
  }

  noNext(): boolean {
    return this.page === this.totalPages;
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public selectPage(page: number | string, event?: Event): void {
    if (page == '...' || typeof page == 'string') {
      return;
    }

    if (event) {
      event.preventDefault();
    }

    if (!this.disabled) {
      if (event && event.target) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const target: any = event.target;
        target.blur();
      }
      this.writeValue(page);
      this.onChange(this.page);
    }
  }

  // Créer un objet de page utilisé dans le modèle
  protected makePage(
    num: number,
    text: string,
    active: boolean
  ): { number: number; text: string; active: boolean } {
    return { text, number: num, active };
  }

  // 
  protected getPages(currentPage: number, totalPages: number): PagesModel[] {
    const pages: PagesModel[] = [];

    // Default page limits
    let startPage = 1;
    let endPage = totalPages;

    // Add page number links
    for (let num = startPage; num <= endPage; num++) {
      const page = this.makePage(num, num.toString(), num === currentPage);
      pages.push(page);
    }

    return pages;
  }
  
  // Classe de base
  protected calculateTotalPages(): number {
    const totalPages =
      this.itemsPerPage < 1
        ? 1
        : Math.ceil(this.totalItems / this.itemsPerPage);

    return Math.max(totalPages || 0, 1);
  }
}
