import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { InputBaseComponent } from '../input-base.component';

/**
 * Composant input text
 */
@Component({
  selector: 'dsfr-input-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextComponent extends InputBaseComponent {

  @Input() inputGroupLabel: string | undefined;

  @Input() hintText: string | undefined;

  @Input() isModal: boolean = false;
}
