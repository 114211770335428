import { Component, ViewChild, OnDestroy, OnInit  } from '@angular/core';
import { FormBuilder, Validators, FormControl, FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { ModalService } from "@mjfr/ng-dsmj";

import { environment  } from '@/environments/environment';
import { AttestationService } from '@services/attestation/attestation.service';
import { AlertService } from '@services/alert/alert.service';
import { UtilisateurService } from '@services/utilisateur/utilisateur.service';
import { AuthentificationService } from '@services/authentification/authentification.service';

import { AttestationComponent as AttestationFormChild} from '../attestation/attestation.component';
import { JusticiableComponent as JusticiableFormChild} from '../justiciable/justiciable.component';
import { AffaireComponent as AffaireFormChild} from '../affaire/affaire.component';
import { MissionComponent as MissionFormChild} from '../mission/mission.component';
import { AuxiliaireComponent as AuxiliaireFormChild} from '../auxiliaire/auxiliaire.component';

import { Attestation } from '@/app/models/attestation';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class CreateComponent implements OnDestroy, OnInit  {

  /** Composant enfant - Attestation */
  @ViewChild('attestationChild', {static: true}) attestationChild: AttestationFormChild;
    /** Composant enfant - Justiciable */
  @ViewChild('justiciableChild', {static: true}) justiciableChild: JusticiableFormChild;
  /** Composant enfant - Affaire */
  @ViewChild('affaireChild', {static: true}) affaireChild: AffaireFormChild;
  /** Composant enfant - Auxiliaire */
  @ViewChild('auxiliaireChild', {static: true}) auxiliaireChild: AuxiliaireFormChild;
  /** Composant enfant - Mission */
  @ViewChild('missionChild', {static: true}) missionChild: MissionFormChild;

  /** Socle du formulaire */
  formGroup = this.fb.group({
    id: undefined,
    status: 'En cours',
    justiciable: this.fb.group({
      id: undefined,
      civilite: ['', [Validators.required]],
      nom: ['', [Validators.required]],
      prenom: ['', [Validators.required]],
      nomUsage: [''],
      dateNaissance: new FormControl<Date|null>(null),
      lieuNaissance: [''],
      paysNaissance: [''],
      adresse: [''],
      codePostal: [''],
      ville: [''],
      pays: [''],
    }),
    affaire: this.fb.group({
      id: undefined,
      typeAffaire: ['', [Validators.required]],
      numeroAffaire: [''],
      personneAssistee: [''],
      partieAdverse: ['', [Validators.required]],
      aideJuridic: ['', [Validators.required]],
      pourcentageAj: ['', [Validators.required]],
      dateBaj: new FormControl<Date|null>(null),
      numeroBaj: [''],
      ajGarantie: new FormControl<boolean|null>(null),

    }),
    auxiliaire: this.fb.group({
      id: undefined,
      nom: ['', [Validators.required]],
      prenom: ['', [Validators.required]],
      barreau: ['', [Validators.required]],
      typeAuxiliaire: ['', [Validators.required]],
      typeAvocat: ['', [Validators.required]],
    }),
    mission: this.fb.group({
      id: undefined,
      refMission: new FormControl<number|null>(null, [Validators.required]),
      nombreUv: new FormControl<number|null>(null),
      dateAccomplissement: new FormControl<Date|null>(null, [Validators.required]),
      total: new FormControl<number|null>(null),
      existMajoration: new FormControl<boolean|null>(null, [Validators.required]),
    }),
  });

  applicationName: String = '';
  attestation : Attestation;
  id = new BehaviorSubject<number | undefined>(undefined);
  loaded: boolean = true;
  title: string = "Nouvelle attestation de mission";

  protected _saveSates = new Subject();
  isUpdate: boolean = false;

  isStep1: boolean = true;
  isStep2: boolean = false;
  isStep3: boolean = false;
  //isStep4: boolean = false;


  public missionList: {[key:string]:string} = {
    '1': 'Divorce par consentement mutuel judiciaire',
    '2': 'Divorce par consentement mutuel judiciaire. Les deux époux ont l’aide juridictionnelle et le même auxiliaire (décision d’admission à l’aide juridictionnelle prononcée entre le 1er septembre 2018 et le 31 décembre 2019) ',
    '3': 'Administration légale, tutelle des mineurs, protection juridique des majeurs',
    '4': 'Baux d’habitation ‐ Instances au fond',
    '5': 'Instance au fond devant le tribunal judiciaire avec représentation obligatoire et tribunal de commerce',
    '6': 'Procédure judiciaire de mainlevée et de contrôle des mesures d’isolement ou de contention (en première instance et en appel',
    '7': 'Appel et recours dans les procédures d’appel avec représentation obligatoire',
    '8': 'Mesure de médiation ordonnée par le juge (décision d’aide juridictionnelle entre le 1er janvier 2017 et le 31 décembre 2020)',
    '9': 'Conclusion d’une convention de procédure participative qui a permis de mettre l’affaire en état d’être jugée',
  };


  constructor(
    protected readonly metaTitle: Title,
    protected fb: FormBuilder,
    protected service: AttestationService,
    protected alertService: AlertService,
    protected utilisateurService: UtilisateurService,
    protected authenticateService: AuthentificationService,
    protected router: Router,
    protected modalService: ModalService
  ) {
    this.applicationName = environment.application_name;
  }

  get id$ (): Observable<number | undefined> {
    if (this.id && this.id.value) {
      return this.id.asObservable();
    } else {
      return of(undefined);
    }
  }

  ngOnInit(): void {
    this.metaTitle.setTitle(this.title + ' - ' + this.applicationName);
  }

  /** Initialise les unsubscribes */
  ngOnDestroy() {
    this._saveSates.unsubscribe();
  }

  /** Initialise la sauvegarde */
  protected _save() {
    return this.service.post(this.formGroup.getRawValue());
  }

  getEmail() :String{
    return this.authenticateService.user.email!;
  }

  submit() {
    // Vérification global
    this.formGroup.markAllAsTouched();
    this.formGroup.updateValueAndValidity();

    // Si le formulaire est invalid on retourne sur le formulaire
    if (this.formGroup.invalid) {
      return
    }

    this._save().subscribe({
      next: v => {
        this._saveSates.next(v)
        this.isUpdate = this.id.value == v.id;
        this.id.next(v.id);
        this.attestation = v;
      },
      error: (_) => {
        this._saveSates.error(_)
        this.alertService.error('Enregistrement échoué', {autoClose: true})
      },
      complete: () => {
        this._saveSates.complete();
          this.router.navigate(['/attestations/synthese/', this.id.value], {queryParams: {mode: 'write', create: !this.isUpdate}});
      }
    });
    return this._saveSates
  }

  /** Redirection vers la page précédente */
  protected goBack() {
    this.router.navigate(['/accueil']);
  }

  toStep1() {
    this.isStep1 = true;
    this.isStep2 = false;
    this.isStep3 = false;
    //this.isStep4 = false;
  }

  toStep2() {

    this.justiciable?.markAllAsTouched();
    if (this.justiciable?.invalid) {
      return
    }

    this.isStep1 = false;
    this.isStep2 = true;
    this.isStep3 = false;
    //this.isStep4 = false;
  }

/*   toStep3() {

    this.auxiliaire?.markAllAsTouched();
    if (this.auxiliaire?.invalid) {
      return
    }

    this.isStep1 = false;
    this.isStep2 = false;
    this.isStep3 = true;
    this.isStep4 = false;
  } */

  toStep3() {

    this.auxiliaire?.markAllAsTouched();
    this.affaire?.markAllAsTouched();
    if (this.auxiliaire?.invalid || this.affaire?.invalid) {
      return
    }

    this.isStep1 = false;
    this.isStep2 = false;
    this.isStep3 = true;
  }

  get justiciable() {
    return this.formGroup?.get('justiciable');
  }

  get affaire() {
    return this.formGroup?.get('affaire');
  }

  get auxiliaire() {
    return this.formGroup?.get('auxiliaire');
  }

}

