<div class="fr-grid-row fr-grid-row--gutters">
  <div class="fr-col-12 fr-mt-3w">
    <dsmj-breadcrumb></dsmj-breadcrumb>
  </div>

  <div class="fr-col-6 fr-col-md-4 fr-col-lg-4 login-panel">
    <h3>Connexion à SIAM</h3>
    <h6>Se connecter avec son compte</h6>

    <form class="fr-form-group" [formGroup]="formGroup">
      <dsfr-input-text label="Identifiant" ctrlName="username" hintText="Format attendu : prenom.nom@domaine.fr">
        <span textError>
          Ce champ est obligatoire
        </span>
      </dsfr-input-text>
      <input-password label="Mot de passe" nameInput="password"></input-password>
      <alert type='error' [msg]="errorMsg" *ngIf="errorMsg" class="fr-mt-3w"></alert>
      <div class="btn-container">
        <button type="submit" class="fr-btn" (click)="login()">Se connecter</button>
      </div>
    </form>
  </div>
</div>
