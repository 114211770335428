import { ChangeDetectorRef, Component, OnInit, AfterContentInit, AfterContentChecked } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Title } from '@angular/platform-browser';

import { environment  } from '@/environments/environment';
import { PageChangedEvent } from '@shared/dsfr/components/pagination/pagination.component';
import { AuthentificationService } from '@services/authentification/authentification.service';
import { AttestationService } from '@services/attestation/attestation.service';
import { RefMissionService } from '@services/referentiel/ref-mission.service';
import { RefMission } from '@/app/models/refMission';

type Filter = { type: string, value: string };

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterContentInit, AfterContentChecked {

  public applicationName: String = '';
  public attestationsList: Array<any>;
  public sort = 'id';
  public reverse = true;
  public search: string;

  /** Filtre depuis l'url */
  public filters: Filter[] = [];

  /** Liste valeur filtre */
  listFilter: {[key:string]:string} = {
    'filter1': 'Filtre 1',
    'filter2': 'Filtre 2',
    'filter3': 'Filtre 3',
    'filter4': 'Filtre 4',
    'filter5': 'Filtre 5',
  };

  public missionList: {[key:string]:string} = {
    '1': 'Divorce par consentement mutuel judiciaire',
    '2': 'Divorce par consentement mutuel judiciaire. Les deux époux ont l’aide juridictionnelle et le même avocat (décision d’admission à l’aide juridictionnelle prononcée entre le 1er septembre 2018 et le 31 décembre 2019) ',
    '3': 'Administration légale, tutelle des mineurs, protection juridique des majeurs',
    '4': 'Baux d’habitation ‐ Instances au fond',
    '5': 'Instance au fond devant le tribunal judiciaire avec représentation obligatoire et tribunal de commerce',
    '6': 'Procédure judiciaire de mainlevée et de contrôle des mesures d’isolement ou de contention (en première instance et en appel',
    '7': 'Appel et recours dans les procédures d’appel avec représentation obligatoire',
    '8': 'Mesure de médiation ordonnée par le juge (décision d’aide juridictionnelle entre le 1er janvier 2017 et le 31 décembre 2020)',
    '9': 'Conclusion d’une convention de procédure participative qui a permis de mettre l’affaire en état d’être jugée',
  };

  /** Pagination */
  public start: number;
  public end: number;
  public itemsPerPage: number = 10;
  public _currentPage: number = 1;
  public totalItems: number;
  public filtered = { count: 0 };

  public refMissionList: Array<RefMission>;
  libelleList: {[key:number]:string} = {};

  constructor(
    private readonly title: Title,
    private route: ActivatedRoute,
    private missiorService: AttestationService,
    public auth: AuthentificationService,
    private cdRef: ChangeDetectorRef,
    private refMissionService: RefMissionService) {
      this.applicationName = environment.application_name;
    }

  private initialPaginate() {
    this._currentPage = 1;
    this.start = this._currentPage - 1;
    this.end = this.itemsPerPage;
  }

  ngOnInit(): void {
    this.missiorService.gets();
    this.refMissionService.gets();
    this.refMissionService.List$.subscribe((data: Array<RefMission>) => {
      this.refMissionList = data;
      this.refMissionList.forEach(mission => {
          this.libelleList[mission.id] = mission.numero
      });
    });
    this.title.setTitle('Liste des attestations - ' + this.applicationName);
  }

  ngAfterContentInit(): void {
    this.initialPaginate();

    this.missiorService.List$.subscribe((data: Array<any>) => {
      this.attestationsList = data;
    });
  }

  ngAfterContentChecked(): void {
    if (this.totalItems != this.filtered.count) {
      this.totalItems = this.filtered.count;
    }

    this.route.params.subscribe((params: Params) => {
      this.filters = [];
      if (Object.keys(params).length > 0) {
        this.createFilter(params);
      }
    });
  }

  loadSearch(value: string) {
    this.search = value;
    this.totalItems = this.filtered.count;
    this.cdRef.detectChanges();
  }

  /**
   * Ajoute les filtres dans un tableau
   */
  private createFilter(params: Params): void {

    Object.entries(params).forEach(entry => {
      const [key, value] = entry;
      let filter: Filter;
      filter = {
        type: key,
        value: value
      }
      this.filters.push(filter);
    });
  }

  /**
   * Trier la liste des attestations en fonction de la colonne dans les paramètres
   * @param sort
   */
  public order(sort: string) {
    this.reverse = this.sort === sort ? !this.reverse : false;
    this.sort = sort;
  }

  /** Change la page à afficher */
  public pageChanged($event: PageChangedEvent) {
    this.start = ($event.page - 1) * $event.itemsPerPage
    this.end = $event.page * $event.itemsPerPage
    this._currentPage = $event.page;
  }

  isGreffier() {
    return this.auth.user.roles?.[0] == 'ROLE_GREFFIER';
  }

}

