import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { DsmjBreadcrumbModule } from '@mjfr/ng-dsmj';
import { TextModule } from './dsfr/text.module';
import { TextInputsModule } from './dsfr/text-inputs.module';
import { AlertModule } from './dsfr/alert.module';
import { PasswordModule } from './dsfr/password.module';

const SHARED_MODULES = [
  CommonModule,
  ReactiveFormsModule,
  DsmjBreadcrumbModule,
  TextModule,
  TextInputsModule,
  AlertModule,
  PasswordModule
];


@NgModule({
  imports: [...SHARED_MODULES],
  exports: [...SHARED_MODULES],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule { }
