<div class="fr-input-group fr-input-group--{{group}}" [ngClass]="inputClass" [formGroup]="formGroup">
  <label class="fr-label" [attr.for]="ctrlName" [ngClass]="{'input-required' : validators?.required ?? undefined}">
      {{ label }}
      <i class="asterix" *ngIf="validators?.required" >*</i>
      <ng-content *ngIf="isModal" select="[modal]"></ng-content>
      <span class="fr-hint-text" *ngIf="hintText">{{ hintText }}</span>
  </label>
  
  <input class="fr-input" [name]="ctrlName" formControlName="{{ ctrlName }}"
    [attr.disabled]="isDisabled ? 'true' : null"
    [required]="validators?.required ?? undefined"
    [id]="ctrlName"
    type="text">
  <div class="input-group-text" *ngIf="inputGroupLabel">{{ inputGroupLabel }}</div>
  
  <p *ngIf="group == 'error'" id="{{ctrlName}}-desc-error" class="fr-error-text">
    <ng-content select="[textError]"></ng-content> Champ obligatoire
  </p>
</div>