<div class="fr-input-group" [class]="inputClass" [formGroup]="formGroup">
  <label class="fr-label" [attr.for]="ctrlName" [class]="{'input-required' : required}">
    {{ label }}
  </label>

  <input class="fr-input" [name]="ctrlName" formControlName="{{ ctrlName }}"
    [attr.disabled]="isDisabled ? 'true' : null"
    [required]="required"
    [id]="ctrlName"
    type="text">
  <div class="input-group-text" *ngIf="inputGroupLabel">{{ inputGroupLabel }}</div>

  <p *ngIf="isError" id="{{ctrlName}}-desc-error" class="fr-error-text">
    <ng-content select="[textError]"></ng-content>
  </p>
</div>
