<dsmj-footer>
  <dsmj-footer-bottom>
    <li>
      <a href="#">Plan du site</a>
    </li>
    <li>
      <a href="#">Accessibilité : non/partiellement/totalement conforme</a>
    </li>
    <li>
      <a href="#">Mentions légales</a>
    </li>
    <li>
      <a href="#">Données personnelles</a>
    </li>
    <li>
      <a href="#">Gestion des cookies</a>
    </li>
  </dsmj-footer-bottom>
</dsmj-footer>