<div class="fr-skiplinks">
  <nav class="fr-container" role="navigation" aria-label="Accès rapide">

    <ul class="fr-skiplinks__list">
      <li>
        <a class="fr-link" [routerLink]="[url]" fragment="header-navigation" target="_self">Menu</a>
      </li>
      <li>
          <a class="fr-link" [routerLink]="[url]" fragment="contenu" target="_self">Contenu</a>
      </li>
      <li>
          <a class="fr-link" [routerLink]="[url]" fragment="footer" target="_self">Pied de page</a>
      </li>
    </ul>

  </nav>
</div>
