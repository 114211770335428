import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router} from '@angular/router';
import { Title } from '@angular/platform-browser';

import { environment  } from '@/environments/environment';
import { Attestation } from '@/app/models/attestation';
import { AttestationService } from '@services/attestation/attestation.service';
import { ModalComponent, ModalService } from '@mjfr/ng-dsmj';
import { Subject } from 'rxjs';
import { Country } from '@shared/referentiel/country';
import { RefMissionService } from '@services/referentiel/ref-mission.service';
import { RefMajorationService } from '@services/referentiel/ref-majoration.service';
import { RefMission } from '@/app/models/refMission';
import { RefMajoration } from '@/app/models/refMajoration';

@Component({
  selector: 'form-recap',
  templateUrl: './recap.component.html',
  styleUrls: ['./recap.component.scss']
})
export class RecapComponent implements OnInit {

  applicationName: String = '';
  id: any;
  modeWrite: boolean = false;
  modeCreate: boolean = false;
  attestation: Attestation;
  showAlerte: boolean = false;
  protected _saveSates = new Subject();
  public refMissionList: Array<RefMission>;
  public refMajorationList: Array<RefMajoration>;
  libelleList: {[key:number]:string} = {};
  libelleMajList: {[key:number]:string} = {};

  missionList: {[key:string]:string} = {
    '1': 'Divorce par consentement mutuel judiciaire',
    '2': 'Divorce par consentement mutuel judiciaire. Les deux époux ont l’aide juridictionnelle et le même avocat (décision d’admission à l’aide juridictionnelle prononcée entre le 1er septembre 2018 et le 31 décembre 2019) ',
    '3': 'Administration légale, tutelle des mineurs, protection juridique des majeurs',
    '4': 'Baux d’habitation ‐ Instances au fond',
    '5': 'Instance au fond devant le tribunal judiciaire avec représentation obligatoire et tribunal de commerce',
    '6': 'Procédure judiciaire de mainlevée et de contrôle des mesures d’isolement ou de contention (en première instance et en appel',
    '7': 'Appel et recours dans les procédures d’appel avec représentation obligatoire',
    '8': 'Mesure de médiation ordonnée par le juge (décision d’aide juridictionnelle entre le 1er janvier 2017 et le 31 décembre 2020)',
    '9': 'Conclusion d’une convention de procédure participative qui a permis de mettre l’affaire en état d’être jugée',
  };

  pourcentageList: {[key:string]:string} = {
    '100': '100 %',
    '55': '55 %',
    '25': '25 %', 
  };

  pays: {[key:string]:string} = Country;

  /** Récupère le attestation pour le recap */
  protected _getAttestation(id :number) {
    return this.service.getById(id);
  }

  /** Redirection vers la page précédente */
  protected goBack() {
    this.router.navigate(['/accueil']);
  }

  constructor(
    private readonly title: Title,
    private route: ActivatedRoute,
    protected router: Router,
    protected service: AttestationService,
    protected modalService: ModalService,
    private refMissionService: RefMissionService,
    private refMajorationService : RefMajorationService)
    {
      this.id = this.route.snapshot.paramMap.get('id');
      this.modeWrite = this.route.snapshot.queryParams['mode'] == 'write';
      this.modeCreate = this.route.snapshot.queryParams['create'] == 'true';
      this.applicationName = environment.application_name;
  }

  hideAlerte():void{
    this.showAlerte = false;
  }

  ngOnInit(): void {
    if (this.modeWrite) {
      this.showAlerte = true;
    }
    
    this._getAttestation(this.id).subscribe({
      next: v => {
        this.attestation = v;
      },
      error: (_) => {
        console.error("erreur au chargement du attestation")
      }
    });

    this.refMissionService.gets();
    this.refMajorationService.gets();
    this.refMissionService.List$.subscribe((data: Array<RefMission>) => {
      this.refMissionList = data;
      this.refMissionList.forEach(mission => {
          this.libelleList[mission.id] = mission.numero + ' - ' + mission.libelle
          this.refMajorationList = mission.refMajorations;
          this.refMajorationList.forEach(majoration => {
            this.libelleMajList[majoration.id] = majoration.numero + ' - ' + majoration.libelle
          });
      });
    });

    this.title.setTitle('Récapitulatif du attestation - ' + this.applicationName);
  }

  validateModal() {
    const modalRef = this.modalService.open(ModalComponent, {
      title: "Valider l'attestation", // titre de la modal
      message: "Etes-vous sûr de vouloir valider l'attestation "+ this.attestation?.id, // message à afficher à l'intérieur de la modale
      buttons: {
        confirm: {
          label: 'Valider',
          classes: 'fr-btn fr-btn--primary fr-col-6 fr-col-md-4',
          call: () => {
           modalRef.close('confirm');
          }
        },
        cancel: {
          label: 'Annuler',
          classes: 'fr-btn fr-btn--secondary fr-col-6 fr-col-md-4',
          call: () => {
            modalRef.close('cancel')
          }
        }
      }
    });

    modalRef.onResult().subscribe({
      // action à effectuer lorsque la modale est fermé via un bouton d'action
      next: closed => {
        if (closed == 'confirm') {
          this.validate();
        }
      },
      // action à effectuer lorsque la modale est fermé
      // via le bouton du haut ou le bouton d'action 'cancel'
      error: dismissed => {
      },
      // action à effectuer après la fermeture de la modale
      complete: () => {
      }
    });
  }

  protected _changeStatus() {
    this.attestation.status = 'Validée'
    return this.service.post(this.attestation);
  }

  validate() {

    this._changeStatus().subscribe({
      next: v => {
        this._saveSates.next(v)
        this.attestation = v;
      },
      error: (_) => {
        this._saveSates.error(_)
      },
      complete: () => {
        this._saveSates.complete();
      }
    });
    return this._saveSates
  }

}
