import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AlertComponent } from '../dsfr/components/alert/alert.component';
import { AlertComponent as BaseAlertComponent } from '../dsfr/components/alert/base/alert.component';

@NgModule({
    imports: [CommonModule, FormsModule, ReactiveFormsModule],
    declarations: [AlertComponent, BaseAlertComponent],
    exports: [AlertComponent, BaseAlertComponent, FormsModule]
})

export class AlertModule { }