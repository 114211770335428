import { Component, OnInit } from '@angular/core';
import { ControlContainer, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { Barreau } from '@shared/referentiel/barreau';

@Component({
  selector: 'form-create-auxiliaire',
  templateUrl: './auxiliaire.component.html',
  styleUrls: ['./auxiliaire.component.scss']
})
export class AuxiliaireComponent implements OnInit {


  /** FormGroup parent */
  parent: FormGroup;

  isAvocat : boolean = true;

  barreauList = Barreau;

  typeAvocatList = [{value: 'designe', label: 'Commis ou désigné d\'office'}, {value: 'choisi', label: 'Choisi'}];
  
  typeAuxiliaireList: {[key:string]:string} = {
    'avocat': 'Avocat',
    'commissaire': 'Commissaire de justice',
    'greffier': 'Greffier du tribunal de commerce',
    'mediateur' : 'Médiateur',
    'notaire' : 'Notaire',
  };

  constructor(protected _parentContainer: ControlContainer, protected fb: FormBuilder) { }

  ngOnInit(): void {
    this.parent = this._parentContainer.control as FormGroup;
    this.auxiliaire?.patchValue({typeAuxiliaire: 'avocat'});
  }

  checkAuxType() {
    if (this.typeAuxiliaire?.value == "avocat") {
      this.isAvocat = true;
      this.typeAvocat?.setValidators([Validators.required]);
      this.typeAvocat?.updateValueAndValidity();

      this.barreau?.setValidators([Validators.required]);
      this.barreau?.updateValueAndValidity();
    }
    else { 
    this.isAvocat = false;
    this.typeAvocat?.clearValidators();
    this.typeAvocat?.reset();
    this.typeAvocat?.updateValueAndValidity();

    this.barreau?.clearValidators();
    this.barreau?.reset();
    this.barreau?.updateValueAndValidity();
    }
  }

  get auxiliaire() {
    return this.parent?.get('auxiliaire');
  }

  get typeAuxiliaire() {
    return this.auxiliaire?.get('typeAuxiliaire');
  }

  get typeAvocat() {
    return this.auxiliaire?.get('typeAvocat');
  }

  get barreau() {
    return this.auxiliaire?.get('barreau');
  }

}
