import { Injectable } from '@angular/core';
import { catchError, map, Observable, take, BehaviorSubject, Subject } from 'rxjs';
import { HttpClient, HttpResponse, HttpParams } from '@angular/common/http';

import { ApiService } from '../api/api.service';
import { Attestation } from '@/app/models/attestation';

/**
 * Service api pour les données du 
 */
@Injectable({
  providedIn: 'root'
})
export class AttestationService {

  url: string;
  public List$ = new BehaviorSubject<Array<any>>([]);
  public nb$ = new Subject<number>();

  constructor(private apiService: ApiService, public http: HttpClient) {
    this.url = this.apiService.API_URL + '/v1/attestations';
  }

  /**
   * Fill in the  list
   */
  public gets() {
    this.getAll().pipe(take(1)).subscribe( data => {
      this.List$.next(data);
      this.nb$.next(data.length);
    });
  }

  /**
   * Retourne un  avec son id
   */
  getById(id : number): Observable<Attestation> {
    return this.http.get<any>(this.url +'/'+ id, {observe: 'response'}).pipe(
      take(1),
      catchError(this.apiService.handleError),
      map((res: HttpResponse<any>) =>
      res.body)
    )
  }

  /**
   * Retourne toutes les attestations selon le profil connecté
   */
  getAll(): Observable<Attestation[]> {
    return this.http.get<any>(this.url, {observe: 'response'}).pipe(
      take(1),
      catchError(this.apiService.handleError),
      map((res: HttpResponse<any>) => res.body)
    )
  }

  /**
   * Création ou modification d'une attestation
   */
  post(data: any): Observable<any> {
    return this.http.post<Attestation>(this.url, data).pipe(
      take(1),
      catchError(this.apiService.handleError),
      map((res: any) => res)
    )
  }

    /**
   * changer le statut d'une attestation
   */
  changeStatus(id : number, status: String): Observable<any> {
      return this.http.put<any>(this.url +'/change-status/'+ id, status).pipe(
        take(1),
        catchError(this.apiService.handleError),
        map((res: any) => res)
      )
    }

}
