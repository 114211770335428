import {Component, Input, OnDestroy, OnInit} from '@angular/core'
import {Alert, AlertService, AlertType} from './alert.service'
import {Subscription} from 'rxjs'
import {NavigationStart, Router} from '@angular/router'
import {Type} from './base/alert.component'

/** interface type pour la conversion d'un enum en string correspondant */
interface TypeConvert {
  [key: string]: Type
}

/** converti l'enum AlertType en string correspondant */
const convert: TypeConvert = {
  [AlertType.Success]: 'success',
  [AlertType.Warning]: 'warning',
  [AlertType.Info]: 'info',
  [AlertType.Error]: 'error'
}

/**
 * Composant Alerte
 */
@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html'
})
export class AlertComponent implements OnInit, OnDestroy {
  /** source type par défaut de l'alerte */
  @Input() id = 'default-alert'
  /** true - ajout un effet fade (voir la class.fade)*/
  @Input() fade = true

  /** liste des alertes actives */
  alerts: Alert[] = []
  /** souscription a l'ajout d'alertes */
  alertSubscription!: Subscription
  /** souscription au change de route pour la suppression automatique des alertes actives */
  routeSubscription!: Subscription

  constructor(private router: Router, private alertService: AlertService) {
  }

  /** Initialise la gestion des alertes (ajout/suppression) */
  ngOnInit() {
    // Observe l'ajout de nouvelle alerte
    this.alertSubscription = this.alertService.onAlert(this.id)
      .subscribe(alert => {
        // si la nouvelle alerte ne comprend pas message nous l'ignorons,
        // ce cas survient lors du changement de route
        // le changement se faisant nous supprimons les attributs keepAfterRouteChange des alertes en cours
        if (!alert.message) {
          // cherche les alertes avec 'keepAfterRouteChange'
          this.alerts = this.alerts.filter(x => x.options?.keepAfterRouteChange)

          // supprime l'attribut 'keepAfterRouteChange' des alertes trouvées
          this.alerts.forEach(x => delete x.options?.keepAfterRouteChange)
          return
        }

        // ajout des alertes au tampon
        this.alerts.push(alert)

        // initialise l'auto fermeture de l'alerte si configuré
        if (alert.options?.autoClose) {
          setTimeout(() => this.removeAlert(alert), 3000)
        }
      })

    // supprime les alertes en cas de changement de route
    this.routeSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.alertService.clear(this.id)
      }
    })
  }

  /** execute les unsubscribes */
  ngOnDestroy() {
    this.alertSubscription.unsubscribe()
    this.routeSubscription.unsubscribe()
  }

  /** supprime l'alerte */
  removeAlert(alert: Alert) {
    // Check si l'alertes existe toujours
    if (!this.alerts.includes(alert)) return

    if (this.fade) {
      // si l'alerte existe lui ajoute l'option 'fade'
      const tmp = this.alerts.find(x => x === alert)
      if (tmp) {
        tmp.options!['fade'] = true
      }

      // supprime l'alerte après un temps
      setTimeout(() => {
        this.alerts = this.alerts.filter(x => x !== alert)
      }, 250)
    } else {
      // supprime l'alerte
      this.alerts = this.alerts.filter(x => x !== alert)
    }
  }

  /**
   * retourne le type d'alerte <br>
   * retourne par default 'info'
   */
  getType(alert: Alert) {
    return !alert ? convert[AlertType.Info] : convert[alert.type]
  }
}
