<div class="fr-grid-row">
    <div id="contenu" class="fr-col-12">
        <div class="fr-col-12">
          <dsmj-breadcrumb></dsmj-breadcrumb>
        </div>

        <div id="container">
            <ng-container>
                <div [hidden]="!isStep1">
                    <div class="fr-stepper">
                        <h2 class="fr-stepper__title">
                            <span class="fr-stepper__state">Étape 1 sur 3</span>
                           Informations du justiciable
                        </h2>
                        <div class="fr-stepper__steps" data-fr-current-step="1" data-fr-steps="3"></div>
                        <p class="fr-stepper__details">
                            <span class="fr-text--bold">Étape suivante :</span> Informations de l'affaire
                        </p>
                    </div>
                </div>

                <div [hidden]="!isStep2">
                    <div class="fr-stepper" [hidden]="!isStep3">
                        <h2 class="fr-stepper__title">
                            <span class="fr-stepper__state">Étape 2 sur 3</span>
                            Informations de l'affaire
                        </h2>
                        <div class="fr-stepper__steps" data-fr-current-step="2" data-fr-steps="3"></div>
                        <p class="fr-stepper__details">
                            <span class="fr-text--bold">Étape suivante :</span> Mission et majoration(s)
                        </p>
                    </div>
                </div>

                
                <div [hidden]="!isStep3">
                    <div class="fr-stepper" [hidden]="!isStep3">
                        <h2 class="fr-stepper__title">
                            <span class="fr-stepper__state">Étape 3 sur 3</span>
                            Mission et majoration(s)
                        </h2>
                        <div class="fr-stepper__steps" data-fr-current-step="3" data-fr-steps="3"></div>
                    </div>
                </div>
                <span class="txt-subtitle fr-mb-1w asterix">Les champs avec un astérisque* sont obligatoires.</span>
            </ng-container>
        </div>



        

        <form [formGroup]="formGroup" *ngIf="formGroup && loaded">
            <ng-container>
                <section id="register">
                    <div [hidden]="!isStep1">
                        <form-create-justiciable #justiciableChild></form-create-justiciable>
                        <ul class="fr-btns-group fr-btns-group--right fr-btns-group--inline-lg">
                            <li>
                                <button class="fr-btn fr-btn--secondary" (click)="goBack()">
                                    Retour à la liste des attestations
                                </button>
                            </li>
                            <li>
                                <button class="fr-btn" (click)="toStep2()">
                                    Suivant
                                </button>
                            </li>
                        </ul>
                    </div>
                    <div [hidden]="!isStep2">
                        <form-create-auxiliaire #auxiliaireChild></form-create-auxiliaire>
                        <form-create-affaire #affaireChild></form-create-affaire>
                        <ul class="fr-btns-group fr-btns-group--right fr-btns-group--inline-lg">
                            <li>
                                <button class="fr-btn fr-btn--secondary" (click)="toStep1()">
                                    Précédent
                                </button>
                            </li>
                            <li>
                                <button class="fr-btn" (click)="toStep3()">
                                    Suivant
                                </button>
                            </li>
                        </ul>
                    </div>
                    <div [hidden]="!isStep3">
                        <form-create-mission [isUpdate]="isUpdate" [isStep3]="isStep3" #missionChild></form-create-mission>
                        <ul class="fr-btns-group fr-btns-group--right fr-btns-group--inline-lg">
                            <li>
                                <button class="fr-btn fr-btn--secondary" (click)="toStep2()">
                                    Précédent
                                </button>
                            </li>
                            <li>
                                <button class="fr-btn" (click)="submit()">
                                    Enregistrer
                                </button>
                            </li>
                        </ul>
                    </div>                   
                </section>
            </ng-container>

        </form>

    </div>
</div>
