import { ChangeDetectionStrategy, Component, Input, AfterContentInit } from '@angular/core';
import { InputBaseComponent } from '../input-base.component';

/**
 * Composant input select
 */
@Component({
  selector: 'dsfr-input-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectComponent extends InputBaseComponent implements AfterContentInit {

  /** Set value si non rempli */
  @Input() emptyLabel: string | undefined | null;
  @Input() inputValues: {[key:string]:string | null}| undefined | null;
  /** false - affiche une option vide dans la select list */
  @Input() hiddenEmpty: boolean | undefined;

  labelSelected: string = "";

  /**
   * Affiche ou non le label à l'écran
   */
  @Input() labelVisible: boolean = true;

  ngAfterContentInit(): void {
    this.inputValues = this.thisInputValue();
    this.emptyLabel = (this.emptyLabel == null || typeof this.emptyLabel == 'undefined') ? 'Sélectionnez une option' : this.emptyLabel;
    if (this.hiddenEmpty) {
      this.hiddenEmpty = true;
    } else {
      this.hiddenEmpty = undefined;
    }
    this.labelSelected = 'selected';
    if (this.input?.value === null) {
      this.input?.setValue("");
    }
    
  }

  thisInputValue() {
    return (this.inputValues == null || typeof this.inputValues == 'undefined') ? {'yes':'Oui', 'no':'Non', 'partiel':'Partiel' } : this.inputValues;
  }

  returnZero() {
    return 0;
  }
}
