import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule , ReactiveFormsModule} from '@angular/forms';

import { SimpleTextCopyComponent } from './components/inputs/simple-text-copy/simple-text.component';

@NgModule({
    imports: [CommonModule, FormsModule, ReactiveFormsModule],
    declarations: [SimpleTextCopyComponent],
    exports: [SimpleTextCopyComponent],
})

export class TextInputsModule { }