import {Component} from '@angular/core'

/**
 * Composant gérant l'affichage du footer
 */
@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent {}
