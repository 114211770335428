<section id="justiciable" class="fr-accordion">
    <!-- Bloc Justiciable -->
    <h3 class="fr-accordion__title">
        <button class="fr-accordion__btn" aria-expanded="true" aria-controls="accordion-justiciable">Identité du justiciable</button>
    </h3>

    <div class="fr-collapse" id="accordion-justiciable" [formGroup]="parent">
        <div class="fr-grid-row"  formGroupName="justiciable">

            <dsfr-input-radio class="fr-form-group fr-col-12 fr-pt-0w fr-pr-6w" id="civilite" [inputValues]="civilite" ctrlName="civilite" label="Civilité"></dsfr-input-radio>

            <dsfr-input-text class="fr-form-group fr-col-4 fr-pt-0w fr-pr-6w" id="prenom" ctrlName="prenom" label="Prénom(s)"></dsfr-input-text>
            
            <dsfr-input-text class="fr-form-group fr-col-4 fr-pt-0w fr-pr-6w" id="nom" ctrlName="nom" label="Nom de naissance"></dsfr-input-text>

            <dsfr-input-text class="fr-form-group fr-col-4 fr-pt-0w fr-pr-6w" id="nomUsage" ctrlName="nomUsage" label="Nom d'usage"></dsfr-input-text>

            <div class="fr-input-group fr-col-4 fr-pt-0w fr-pr-6w">
                <label class="fr-label" for="dateNaissance">Date de naissance</label>
                <input formControlName="dateNaissance" class="fr-input" type="date" id="dateNaissance" name="dateNaissance">
            </div>
            <dsfr-input-text class="fr-form-group fr-col-4 fr-pt-0w fr-pr-6w" id="lieuNaissance" ctrlName="lieuNaissance" label="Lieu de naissance"></dsfr-input-text>

            <dsfr-input-select ctrlName="paysNaissance" [inputValues]="pays" class="fr-form-group fr-col-4 fr-pt-0w fr-pr-6w" label="Pays de naissance" inputClass="fr-col-12"></dsfr-input-select>
            
        </div>
    </div>

    <h3 class="fr-accordion__title">
        <button class="fr-accordion__btn" aria-expanded="true" aria-controls="accordion-adresse">Adresse du justiciable</button>
    </h3>

    <div class="fr-collapse" id="accordion-adresse" [formGroup]="parent">
        <div class="fr-grid-row"  formGroupName="justiciable">

            <dsfr-input-text class="fr-form-group fr-col-8 fr-pt-0w fr-pr-6w" id="adresse" ctrlName="adresse" label="Adresse"></dsfr-input-text>
            <div class="fr-col-4"></div>

            <dsfr-input-text class="fr-form-group fr-col-3 fr-pt-0w fr-pr-6w" id="codePostal" ctrlName="codePostal" label="Code postal"></dsfr-input-text>
        
            <dsfr-input-text class="fr-form-group fr-col-5 fr-pt-0w fr-pr-6w" id="ville" ctrlName="ville" label="Ville ou commune"></dsfr-input-text>
 
            <dsfr-input-select ctrlName="pays" labelSelected="FR" labelSelected2="FR" [inputValues]="pays" class="fr-form-group fr-col-4 fr-pt-0w fr-pr-6w" label="Pays" inputClass="fr-col-12"></dsfr-input-select>
                 
       
        </div>
    </div>

</section>
