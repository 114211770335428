import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-skiplinks',
  templateUrl: './skiplinks.component.html',
  styleUrls: ['./skiplinks.component.scss']
})
export class SkiplinksComponent {

  @Input() url: string;

  constructor() { }

}
