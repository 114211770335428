<div class="fr-grid-row">
    <div class="fr-col-12 fr-mt-3w">
      <dsmj-breadcrumb></dsmj-breadcrumb>
    </div>

    <div id="contenu" class="fr-col-12 fr-col-md-12 fr-pb-12v">

        <div id="title-row" class="fr-grid-row">
            <div class="fr-col-6">
                <h2>Liste des attestations</h2>
            </div>

            <div class="fr-col-6" *ngIf="isGreffier()">
                <div class="fr-container fr-pr-0">
                    <div class="fr-grid-row fr-grid-row--right fr-mb-3w">
                        <button routerLink="/attestations/creation" class="fr-btn">Créer une attestation</button>
                    </div>
                </div>
            </div>
        </div>


        <div id="tab-title" class="fr-grid-row fr-mb-3w">
            <div class="fr-col-6 vertical-align-end">
                <h6 class="fr-mb-0">Liste des dernières attestations de mission</h6>
            </div>
        </div>

        <div class="fr-table fr-table--layout-fixed">
            <table>

                <thead>
                    <tr>
                        <!-- <th scope="col" class="order" (click)="order('identifiant')">Identifiant <span [ngClass]="(sort === 'identifiant' && !reverse) ? 'fleche-bas-pleine' : 'fleche-bas-vide'"></span> <span [ngClass]="(sort === 'identifiant' && reverse) ? 'fleche-haut-pleine' : 'fleche-haut-vide'"></span></th>
                        <th scope="col" class="order" (click)="order('status')">Statut <span [ngClass]="(sort === 'status' && !reverse) ? 'fleche-bas-pleine' : 'fleche-bas-vide'"></span> <span [ngClass]="(sort === 'status' && reverse) ? 'fleche-haut-pleine' : 'fleche-haut-vide'"></span></th>
                        <th scope="col" class="info" (click)="order('info')">Information <span [ngClass]="(sort === 'info' && !reverse) ? 'fleche-bas-pleine' : 'fleche-bas-vide'"></span> <span [ngClass]="(sort === 'info' && reverse) ? 'fleche-haut-pleine' : 'fleche-haut-vide'"></span></th>
                         -->
                        
                        <th scope="col" class="order" (click)="order('status')">Statut</th>
                        <th scope="col" class="order" (click)="order('justiciable')">Justiciable</th>
                        <th scope="col" class="order" (click)="order('mission')">Mission</th>
                        <th scope="col" class="info" (click)="order('info')">Effectué par</th>
                        <th scope="col" class="order" (click)="order('status')">Décision AJ</th>
                        <th scope="col">Actions</th>
                    </tr>
                </thead>

                <tbody>
                    <tr *ngFor="let attestation of attestationsList">
                        
                        <td> {{ attestation.status }} </td>
                        <td> {{ attestation?.justiciable?.nom }} {{ attestation?.justiciable?.prenom }} </td>
                        <td> {{ missionList[attestation?.mission] }}</td>
                        <td> {{ attestation.info }} </td>
                        <td> {{ attestation?.affaire?.aideJuridic }} {{ attestation?.affaire?.pourcentageAj }} </td>
                        <td class="inline">
                            <a [routerLink]="['/attestations/synthese', attestation.id]" class="fr-link active"><span class="fr-icon fr-icon-search-line" aria-hidden="true"></span></a>
                            <a [routerLink]="['/attestations/edition', attestation.id]" class="fr-link active" *ngIf="isGreffier()"><span class="fr-icon fr-icon-draft-line" aria-hidden="true"></span></a>
                        </td>
                    </tr>
                </tbody>

            </table>
        </div>

        <div class="fr-grid-row">
            <div class="fr-col-12">
                <dsfr-pagination
                [totalItems]="totalItems"
                [itemsPerPage]="itemsPerPage"
                (pageChanged)="pageChanged($event)"
                [(ngModel)]="_currentPage"></dsfr-pagination>
            </div>
        </div>
    </div>
</div>

