import { ChangeDetectionStrategy, Component, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';

import { InputBaseComponent } from '../input-base.component';
import { InputValue } from '../InputValue';

@Component({
  selector: 'dsfr-input-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RadioComponent extends InputBaseComponent {
  
  @Input() defaultValue: any;

  @Input() inputValues: InputValue[] = [{value: true, label: 'Oui'}, {value: false, label: 'Non'}];

  @Output() returnValue = new EventEmitter<any>();

  returnChangeValue(value: string) {
    this.returnValue.emit(value);
  }

}