import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';

import { environment } from '../../../environments/environment';

/**
 * Service contenant les éléments de base d'un service api
 */
@Injectable({
  providedIn: 'root'
})
export class ApiService {
  /** url de l'api */
  API_URL: string = environment.api

  /** gestionnaire d'erreur */
  handleError(error: any): Observable<never> {
    let errorMessage = ''
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`
    }
    return throwError(() => errorMessage)
  }
}
