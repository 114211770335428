<section id="attestation" class="fr-accordion">
    <!-- Bloc attestation -->
    <h3 class="fr-accordion__title">
        <button class="fr-accordion__btn" aria-expanded="true" aria-controls="accordion-attestation">Attestation</button>
    </h3>

    <div class="fr-collapse" id="accordion-attestation" [formGroup]="formGroup">
        <div class="fr-grid-row">

            <dsfr-input-text ctrlName="id" label="Identifiant" class="fr-col-4"
            inputClass="fr-col-12 fr-pr-4w fr-pt-3w" [required]="false"></dsfr-input-text>

        </div>
    </div>
</section>
