import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule , ReactiveFormsModule} from '@angular/forms';

import { NumberComponent } from './components/inputs/number/number.component';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  declarations: [NumberComponent],
  exports: [NumberComponent]
})
export class NumberModule { }