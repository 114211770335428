import { Component, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Title } from '@angular/platform-browser';

import { ModalService } from "@mjfr/ng-dsmj";
import { CreateComponent } from "./create.component";
import { Attestation } from "@/app/models/attestation";
import { AttestationService } from "@services/attestation/attestation.service";
import { AlertService } from "@services/alert/alert.service";
import { UtilisateurService } from "@services/utilisateur/utilisateur.service";
import { AuthentificationService } from "@services/authentification/authentification.service";

@Component({
  selector: 'app-edit',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class EditComponent extends CreateComponent implements OnInit {

  override attestation : Attestation;
  override loaded: boolean = false;
  override title = "Modification d'une attestation";

  constructor(
    protected route: ActivatedRoute,
    protected override metaTitle: Title,
    protected override fb: FormBuilder,
    protected override service: AttestationService,
    protected override alertService: AlertService,
    protected override utilisateurService: UtilisateurService,
    protected override authenticateService: AuthentificationService,
    protected override router: Router,
    protected override modalService: ModalService) {
    super(metaTitle, fb, service, alertService, utilisateurService, authenticateService, router, modalService);
    this.id.next(Number(this.route.snapshot.paramMap.get('id')));
  }

  override ngOnInit(): void {
    this.isUpdate = true;

    this._getAttestation(this.id.value!).subscribe({
      next: v => {
        this.attestation = v;
        var mission = this.formGroup?.get('mission') as FormGroup;
        mission.addControl('majorations', this.fb.array([this.addMajorationGroup()]));
        if (v.mission.majorations.length > 1) {
          var majorationArray =  mission.get('majorations') as FormArray;
          for (let index = 1; index < v.mission.majorations.length; index++) {
            majorationArray.push(this.addMajorationGroup());
          }
        }
        this.formGroup.patchValue(this.attestation);
      },
      error: (_) => {
        console.error("erreur au chargement de la attestation")
      },
      complete: () => {
        this.loaded = true;
      }
    });

    this.metaTitle.setTitle(this.title + ' - ' + this.applicationName);
  }

  /** Initialise attestation */
  protected _getAttestation(id :number) {
    return this.service.getById(id);
  }

  private addMajorationGroup(): FormGroup {
    return this.fb.group({
      id: undefined,
      refMajoration: new FormControl<string|null>(null, [Validators.required]),
      nombre: new FormControl<number|null>(null, [Validators.required]),
      coefficient: new FormControl<number|null>(null),
      nombreUv: new FormControl<number|null>(null),
    });
  }

}
