import { Component, OnChanges, OnInit } from '@angular/core';
import { ControlContainer, FormGroup } from "@angular/forms";
import { AuthentificationService } from '@services/authentification/authentification.service';
import { UtilisateurService } from '@services/utilisateur/utilisateur.service';


@Component({
  selector: 'form-create-attestation',
  templateUrl: './attestation.component.html',
  styleUrls: ['./attestation.component.scss']
})
export class AttestationComponent implements OnInit {

  /** FormGroup parent */
  formGroup: FormGroup;

  constructor(
    protected _parentContainer: ControlContainer,
    public authenticateService: AuthentificationService,
    protected utilisateurService: UtilisateurService
  ) { }

  ngOnInit(): void {
    this.formGroup = this._parentContainer.control as FormGroup;
  }

  isGreffier() {
    return (this.authenticateService.user.roles?.[0] == 'ROLE_USER_GREFFIER');
  }

}
