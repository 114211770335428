import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule , ReactiveFormsModule} from '@angular/forms';

import { SelectComponent } from './components/inputs/select/select.component';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  declarations: [SelectComponent],
  exports: [SelectComponent]
})
export class SelectModule { }
