import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

import { ApiService } from './../api.service';
import { Utilisateur } from '@/app/models/utilisateur';

@Injectable({
    providedIn: 'root'
})
export class UtilisateurService {

    url: string;

    constructor(private apiService: ApiService, private http: HttpClient) {
        this.url = this.apiService.API_URL + '/utilisateur'
    }

    getInfo(): Observable<Utilisateur> {
        return this.http.get<Utilisateur>(this.url + '/info');
    }

    fetch(): Observable<Utilisateur> {
        return this.http.get<Utilisateur>(this.url + '/email');
    }

    create(utilisateur: Utilisateur): Observable<Utilisateur> {
        return this.http.post<Utilisateur>(this.url, utilisateur);
    }

    update(id: number, utilisateur: Utilisateur): Observable<Utilisateur>{
        return this.http.put<Utilisateur>(this.url + '/' + id, utilisateur);
    }

}
