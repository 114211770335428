<div class="fr-select-group fr-select-group--{{ group }}" [ngClass]="inputClass" [formGroup]="formGroup">
    <label *ngIf="labelVisible" class="fr-label" [attr.for]="ctrlName">
        {{ label }}
        <i class="asterix" *ngIf="validators?.required" >*</i>
    </label>
    <select class="fr-select fr-select--{{group}}" [id]="ctrlName" [name]="ctrlName" formControlName="{{ ctrlName }}" [attr.disabled]="isDisabled">
        <option value="" [attr.selected]="labelSelected" [attr.disabled]="hiddenEmpty" [attr.hidden]="hiddenEmpty">
            {{ emptyLabel }}
        </option>
        <option *ngFor="let inputVal of inputValues | keyvalue:returnZero" [value]="inputVal.key" title="{{ inputVal.value }}">
            {{ inputVal.value }}
        </option>
    </select>
    <p *ngIf="group == 'error'" id="{{ctrlName}}-desc-error" class="fr-error-text">
        Sélection obligatoire
    </p>
</div>