import { Majoration } from '@/app/models/majoration';
import { RefMajoration } from '@/app/models/refMajoration';
import { RefMission } from '@/app/models/refMission';
import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ControlContainer, FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { RefMajorationService } from '@services/referentiel/ref-majoration.service';
import { RefMissionService } from '@services/referentiel/ref-mission.service';

@Component({
  selector: 'form-create-mission',
  templateUrl: './mission.component.html',
  styleUrls: ['./mission.component.scss']
})
export class MissionComponent implements OnInit {

  @Input() isStep3: boolean = false;
  @Input() isUpdate: boolean = false;

  /** FormGroup parent */
  parent: FormGroup;
  mission: FormGroup;
  majoration : Majoration;
  isMajorationExist : boolean = false;
  isLimitUv : boolean = false;
  currentMajId : number;
  totalUvMajorations : number = 0;
  public refMissionList: Array<RefMission>;
  public refMajorationList: Array<RefMajoration>;

  libelleList: {[key:number]:string} = {};
  libelleMajList: {[key:number]:string} = {};

  ngOnInit(): void {
    this.parent = this._parentContainer.control as FormGroup;
    this.mission = this.parent?.get('mission') as FormGroup;
    this.refMissionService.gets();
    this.refMajorationService.gets();
    this.refMissionService.List$.subscribe((data: Array<RefMission>) => {
      this.refMissionList = data;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['isStep3'].currentValue == true) {
      this.libelleList = {};
      this.refMissionList.forEach(mission => {
        if (this.typeAffaire?.value.toLowerCase() == mission.typeAffaire.toLowerCase()) {
          this.libelleList[mission.id] = mission.numero + ' - ' + mission.libelle
        }
      });
      if (this.isUpdate) {
        this.getMissionSelected();
        for (let index = 0; index < this.majorationArray.length; index++) {
          this.getMajorationSelected(index);
        }
      }
    }
  }

  constructor(
    protected _parentContainer: ControlContainer,
    private _fb: FormBuilder,
    private refMissionService: RefMissionService,
    private refMajorationService : RefMajorationService
    ) {
  }

  getMissionSelected() {
    this.refMissionList.forEach(mission => {
      if (mission.id == this.missionId?.value) {
        this.mission?.patchValue({nombreUv: mission.uv});
        this.mission?.patchValue({refMission: mission.id});
        this.missionUv?.disable();
        this.refMajorationList = mission.refMajorations;
        this.libelleMajList = {};
        this.refMajorationList.forEach(majoration => {
          this.libelleMajList[majoration.id] = majoration.numero + ' - ' + majoration.libelle
        });
      }
      this.calculTotal();
    });
  }

  getMajorationSelected(index: number) {
    this.currentMajId = index;
    var refMajorationId = this.majorationByIndex?.value.refMajoration;
    this.refMajorationList.forEach(majoration => {
    if (majoration.id == refMajorationId) {
      if (!this.isUpdate || !this.majorationByIndex?.controls?.['nombre'].value) {
        this.majorationByIndex?.patchValue({nombre: majoration.nombre});
      }
    this.majorationByIndex?.patchValue({coefficient: majoration.coefficient});
    this.majorationByIndex?.patchValue({nombreUv: Number(this.majorationByIndex?.controls?.['nombre'].value)*Number(this.majorationByIndex?.controls?.['coefficient'].value)});
    }
    });
    this.majorationByIndex?.controls?.['nombreUv']?.disable();
    this.majorationByIndex?.controls?.['coefficient']?.disable();
    if (this.majorationByIndex?.controls?.['nombre'].value && !this.isUpdate) {
      this.majorationByIndex?.controls?.['nombre']?.disable();
    } else {
      this.majorationByIndex?.controls?.['nombre']?.enable();
    }
    this.majorationByIndex?.updateValueAndValidity();
    this.calculTotal();
  }

  getNombre(index: number) {
    this.currentMajId = index;
    var nombre = this.majorationByIndex?.controls?.['nombre'].value;
    var coefficient = this.majorationByIndex?.controls?.['coefficient'].value;
    this.majorationByIndex?.patchValue({nombreUv: Number(nombre)*Number(coefficient)});
    this.calculTotal();
  }

  private calculTotal() {
    this.totalUvMajorations = 0;
    var total = Number(this.missionUv?.value);
    this.majorationArray?.controls?.forEach(majoration => {
      var maj = majoration as FormGroup;
      total += Number(maj?.controls?.['nombreUv'].value);
      this.totalUvMajorations += Number(maj?.controls?.['nombreUv'].value);
    }); 
    this.mission?.patchValue({total: total});
    if (this.totalUvMajorations > 24 && (this.typeAffaire?.value == "Civile" || this.typeAffaire?.value == "Administrative")) {
      this.isLimitUv = true;
    } else {
      this.isLimitUv = false;
    }
  }

  //Append Fields Set
  private addMajorationGroup(): FormGroup {
    return this._fb.group({
      id: undefined,
      refMajoration: new FormControl<string|null>(null, [Validators.required]),
      nombre: new FormControl<number|null>(null, [Validators.required]),
      coefficient: new FormControl<number|null>(null),
      nombreUv: new FormControl<number|null>(null),
    });
  }

  checkMajoration(event: boolean): void {
    this.isMajorationExist = event;
    if (this.isMajorationExist) {
      this.mission.removeControl('majorations');
      this.mission.addControl('majorations', this._fb.array([this.addMajorationGroup()]));
      this.mission.addControl('total', new FormControl<number|null>(null));
      this.mission.updateValueAndValidity();
    } else {
      this.mission.removeControl('majorations');
      this.mission.addControl('majorations', this._fb.array([]));
      this.mission.removeControl('total');
      this.mission.addControl('total', new FormControl<number|null>(null));
      this.mission.updateValueAndValidity();
      this.calculTotal();
    }
  }

  //Add Fields
  addMajoration(): void {
    this.majorationArray.push(this.addMajorationGroup());
  }

  //Remove Fields
  removeMajoration(index: number): void {
    this.majorationArray.removeAt(index);
    this.calculTotal();
  }

  get majorationByIndex() {
    return this.majorationArray?.get(this.currentMajId.toString()) as FormGroup;
  }

  //Fields Array
  get majorationArray(): FormArray {
    return <FormArray>this.mission.get('majorations');
  }

  get existMajoration() {
    return this.missionParent?.get('existMajoration')?.value;
  }

  get missionParent() {
    return this.parent?.get('mission');
  }

  get missionId() {
    return this.mission?.get('refMission');
  }

  get missionUv() {
    return this.mission?.get('nombreUv');
  }

  get typeAffaire() {
    return this.affaire?.get('typeAffaire');
  }

  get affaire() {
    return this.parent?.get('affaire');
  }

  get dateAccomplissement() {
    return this.mission?.get('dateAccomplissement');
  }

  get dateAccomplissementGroup() {
    return (this.dateAccomplissement?.touched || this.dateAccomplissement?.dirty) && this.dateAccomplissement?.errors ? 'error' : '';
  }

}
