import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  url: string;

  config = {
    title: 'SIAM'
  }

  constructor(private title: Title, private router: Router) {}

  ngOnInit() {
    this.title.setTitle(this.config.title);

    this.router.events.subscribe( event => {
      if (event.constructor.name == 'ResolveEnd') {
        Object.entries(event).forEach(entry => {

          const [key, value] = entry;
          if (key == 'url') {
            this.url = value;
          }

        });
      }
    });

  }

}
