import {Injectable} from '@angular/core'
import {filter, Observable, Subject} from 'rxjs'

/** constitution d'une alerte */
export interface Alert {
  /** source type de l'alerte */
  id: string
  /** type de l'alerte */
  type: AlertType
  /** contenu de l'alerte */
  message: string
  /** titre de l'alerte - non utilisé */
  title: string
  /** options de gestion */
  options?: Options
}

/** constitution des options d'une alerte */
export interface Options {
  /** si l'alerte doit se fermer automatiquement */
  autoClose?: boolean
  /** si l'alerte ne doit pas être supprimée durant un changement de page*/
  keepAfterRouteChange?: boolean
  /** s'il doit y avoir un effet de 'fade' à la fermeture de l'alerte */
  fade?: boolean
}

/** type d'alerte possible */
export enum AlertType {
  Success,
  Error,
  Info,
  Warning
}

/**
 * Service de gestion des données d'alertes accessible par souscription
 */
@Injectable()
export class AlertService {
  /** contient la liste des alertes en cours */
  private subject = new Subject<Alert>()
  /** source type par défaut de l'alerte */
  private defaultId = 'default-alert'

  /** Permet la souscription à l'ajout de nouvelle alerte selon son id */
  onAlert(id = this.defaultId): Observable<Alert> {
    return this.subject.asObservable().pipe(filter(x => x && x.id === id))
  }

  /** Permet l'ajout d'une alerte dans la liste des observables */
  alert(alert: Alert) {
    alert.id = alert.id || this.defaultId
    // le titre, prévu par le dsfr, n'est pas utilisé dans les maquettes actuelles.
    alert.title = ''
    this.subject.next(alert)
  }

  /** Vide les alertes par id */
  clear(id = this.defaultId) {
    this.subject.next({id} as Alert)
  }

  /** créé une alerte de type success */
  success(message: string, options?: Options) {
    this.alert({type: AlertType.Success, message, options} as Alert)
  }

  /** créé une alerte de type success */
  error(message: string, options?: Options) {
    this.alert({type: AlertType.Error, message, options} as Alert)
  }

  /** créé une alerte de type info */
  info(message: string, options?: Options) {
    this.alert({type: AlertType.Info, message, options} as Alert)
  }

  /** créé une alerte de type warning */
  warn(message: string, options?: Options) {
    this.alert({type: AlertType.Warning, message, options} as Alert)
  }
}