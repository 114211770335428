<nav role="navigation" class="fr-pagination" aria-label="Pagination">
    <ul class="fr-pagination__list">
      <li [class.disabled]="noPrevious() || disabled">
        <a [attr.href]="page != 1 ? '#' : null" class="fr-pagination__link fr-pagination__link--first" [attr.aria-disabled]="(page == 1)" role="link"
        (click)="selectPage(1, $event)">
          Première page
        </a>
      </li>

      <li [class.disabled]="noPrevious() || disabled">
        <a [attr.href]="page != 1 ? '#' : null" class="fr-pagination__link fr-pagination__link--prev fr-pagination__link--lg-label" [attr.aria-disabled]="(page == 1)"
           role="link" (click)="selectPage(page - 1, $event)">
          Page précédente
        </a>
      </li>
      
      <li *ngFor="let pg of pages" [class.disabled]="disabled && !pg.active">
        <a [attr.href]="!disabled && !pg.active ? '#' : null" class="fr-displayed-lg" (click)="selectPage(pg.number, $event)" [class.fr-pagination__link]="pg.number !== '...'" [title]="'Page ' + pg.number"
            [attr.aria-current]="pg.number === page ? 'page' : null">
          {{pg.number}}
        </a>
      </li>
      
      <li [class.disabled]="noNext() || disabled">
        <a [attr.href]="totalPages != page ? '#' : null" class="fr-pagination__link fr-pagination__link--next fr-pagination__link--lg-label"
          (click)="selectPage(page + 1, $event)">
          Page suivante
        </a>
      </li>

      <li [class.disabled]="noNext() || disabled">
        <a [attr.href]="totalPages != page ? '#' : null" class="fr-pagination__link fr-pagination__link--last" [attr.aria-disabled]="(page == totalPages)"
        (click)="selectPage(totalPages, $event)">
          Dernière page
        </a>
      </li>
    </ul>
</nav>
  