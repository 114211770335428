<dsmj-header organisationName="SIAM" organisationBaseline="Système d'Information des Attestations de Mission">
  <ng-container>
    <div class="fr-header__tools">
      <div class="fr-header__tools-links">
        <ul class="fr-links-group">
          <li>
            <span class="fr-link">{{auth.user.username}}</span><br/>
          </li>
          <li>
            <button class="fr-link" (click)="logout($event)">Déconnexion
            </button>
          </li>
        </ul>
      </div>
    </div>
  </ng-container>
</dsmj-header>