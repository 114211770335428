import { ChangeDetectionStrategy, Component, Input} from '@angular/core';
import { ControlContainer, NgForm, FormGroup} from '@angular/forms';

/**
 * Composant input text
 */
@Component({
  selector: 'dsfr-simple-text-copy',
  templateUrl: './simple-text.component.html',
  styleUrls: ['./simple-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: NgForm,
    }
  ]
})
export class SimpleTextCopyComponent {
  /** Set le nom et l'id de l'input */
  @Input() ctrlName: string;
  /** Set le label de l'input */
  @Input() label: string = '';
  /** Si l'input est désactivé */
  @Input() isDisabled: boolean = false;
  /** Set class div container */
  @Input() inputClass: string;

  @Input() formGroup: FormGroup;

  @Input() inputGroupLabel: string | undefined;

  @Input() required: boolean = true;
  @Input() isError: boolean = false;

}


