<div class="fr-form-group" [formGroup]="formGroup">
    <fieldset class="fr-fieldset fr-fieldset--inline fr-fieldset--{{group}}" [attr.disabled]="isDisabled">
      <legend class="fr-fieldset__legend fr-text--regular" [attr.id]='ctrlName'>
        {{ label }}
        <i class="asterix" *ngIf="validators?.required" >*</i>
      </legend>
      <div class="fr-fieldset__content">
        <div *ngFor="let value of inputValues; let index = index" class="fr-radio-group">
          <input type="radio" [attr.id]="id + '_' + index" [attr.name]="name" [required]="validators?.required ?? undefined" (click)="returnChangeValue(value.value)" [value]="value.value" [formControlName]="ctrlName">
          <label class="fr-label" [attr.for]="id + '_' + index">
            {{value.label}}
          </label>
        </div>
      </div>
      <p *ngIf="group == 'error'" id="{{ctrlName}}-desc-error" class="fr-error-text">
        Sélection obligatoire
      </p>
    </fieldset>
</div>