import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule , ReactiveFormsModule} from '@angular/forms';

import { RadioComponent } from './components/inputs/radio/radio.component';
import { RadioStringComponent } from './components/inputs/radio/radio-string.component';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  declarations: [RadioComponent, RadioStringComponent],
  exports: [RadioComponent, RadioStringComponent]
})
export class RadioModule { }
