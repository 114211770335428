import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { InputBaseComponent } from '../input-base.component';

/**
 * Composant input text
 */
@Component({
  selector: 'dsfr-input-number',
  templateUrl: './number.component.html',
  styleUrls: ['./number.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NumberComponent extends InputBaseComponent {

  @Input() inputGroupLabel: string | undefined;

  @Input() hintText: string | undefined;

  @Input() isModal: boolean = false;
}
