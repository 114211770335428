<section id="mission" class="fr-accordion">
    <!-- Bloc Mission -->
    <h3 class="fr-accordion__title">
        <button class="fr-accordion__btn" aria-expanded="true" aria-controls="accordion-mission">Mission et majoration(s)</button>
    </h3>

    <div class="fr-collapse" id="accordion-mission" [formGroup]="parent">
        <div class="fr-grid-row"  formGroupName="mission">

            <dsfr-input-select ctrlName="refMission" [inputValues]="libelleList" class="fr-form-group fr-col-11 fr-pt-0w fr-pr-3w" label="Mission" (click)="getMissionSelected()"></dsfr-input-select>
            
            <dsfr-input-text [isDisabled]="true" class="fr-form-group fr-col-1 fr-pt-0w fr-pr-5w" id="nombreUv" ctrlName="nombreUv" label="UV"></dsfr-input-text>

            <div class="fr-input-group fr-input-group--{{ dateAccomplissementGroup }} fr-col-4 fr-pt-0w fr-pr-0w">
                <label class="fr-label" for="dateAccomplissement">Date d'accomplissement de la mission<i class="error-group"> * </i></label>
                <input formControlName="dateAccomplissement" class="fr-input" type="date" id="dateAccomplissement" name="dateAccomplissement">
                <p *ngIf="dateAccomplissementGroup == 'error'" id="dateAccomplissement-desc-error" class="fr-error-text">
                    Champ obligatoire
                </p>
            </div>
        
            <dsfr-input-radio class="fr-form-group fr-col-12 fr-pt-0w fr-pr-3w" id="existMajoration" ctrlName="existMajoration" label="Majoration(s)" (returnValue)="checkMajoration($event)"></dsfr-input-radio>
            
                <!-- Bloc Majoration -->
        <ng-container *ngIf="existMajoration">

            <div  [formGroup]="mission" *ngIf="majorationArray" class="fr-container fr-px-0">
                <div  *ngFor="let group of majorationArray.controls; let i = index" formArrayName="majorations" >
                    <div [formGroupName]="i" class="fr-grid-row">
                        <dsfr-input-select ctrlName="refMajoration" [inputValues]="libelleMajList" class="fr-form-group fr-col-9 fr-pt-0w fr-pr-3w" label="Majoration {{i+1}}" (click)="getMajorationSelected(i)"></dsfr-input-select>
                        <dsfr-input-text class="fr-form-group fr-col-1 fr-pt-0w fr-pr-2w" id="nombre" ctrlName="nombre" label="Nombre" (change)="getNombre(i)"></dsfr-input-text>
                        <dsfr-input-text class="fr-form-group fr-col-1 fr-pt-0w fr-pr-5w" id="coefficient" ctrlName="coefficient" label="Coefficient">-</dsfr-input-text>
                        <dsfr-input-text class="fr-form-group fr-col-1 fr-pt-0w fr-pr-5w" id="nombreUv" ctrlName="nombreUv" label="UV">-</dsfr-input-text>
                        <button class="fr-btn fr-btn--error fr-btn--tertiary-no-outline fr-btn--lg fr-icon-subtract-line delete" *ngIf="i > 0" (click)="removeMajoration(i)" ></button>
                    </div>
                </div>
                    
                <div class="fr-grid-row fr-pt-0w fr-pr-3w">
                    <div class="fr-col-1 fr-pt-3w"><button class="fr-form-group fr-btn fr-btn--tertiary-no-outline fr-btn--lg fr-icon-add-line" (click)="addMajoration()"></button></div>
                    <div class="fr-col-10"> </div>
                    <dsfr-input-text class="fr-form-group fr-col-1 fr-pt-0w fr-pr-2w" id="total" ctrlName="total" label="Total"></dsfr-input-text>
                </div>

                <div *ngIf="isLimitUv" class="fr-grid-row fr-pt-0w fr-pr-3w">
                <p class="error-group"><span class="fr-icon-warning-fill" aria-hidden="true"></span> Les majorations sélectionnées dépassent la limite fixée à 24 UV.</p>
                </div>
            </div>

        </ng-container>
        </div>
    </div>



</section>

