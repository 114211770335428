import { Component, OnInit } from '@angular/core';
import { ControlContainer, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { Country } from '../../../shared/referentiel/country';

@Component({
  selector: 'form-create-justiciable',
  templateUrl: './justiciable.component.html',
  styleUrls: ['./justiciable.component.scss']
})
export class JusticiableComponent implements OnInit {


  /** FormGroup parent */
  parent: FormGroup;

  civilite = [{value: 'madame', label: 'Madame'}, {value: 'monsieur', label: 'Monsieur'}];
  pays = Country;

  constructor(protected _parentContainer: ControlContainer, protected fb: FormBuilder) { }

  ngOnInit(): void {
    this.parent = this._parentContainer.control as FormGroup;
    this.justiciable?.patchValue({pays: 'FR'});
    this.justiciable?.patchValue({paysNaissance: 'FR'});
  }

  get justiciable() {
    return this.parent?.get('justiciable');
  }


}
