<div class="fr-grid-row">
    <div class="fr-col-12 fr-mt-3w">
      <dsmj-breadcrumb></dsmj-breadcrumb>
    </div>

    <div id="contenu" class="fr-col-12 fr-col-md-12 fr-pb-12v">

        <div *ngIf="showAlerte && modeCreate && this.attestation.status != 'Validée'" class="fr-alert fr-alert--success">
            <ng-container>
                <h3 class="fr-alert__title">L'attestation de mission n°{{ attestation?.id }} a bien été enregistrée</h3>
                <a>Vous pouvez la consulter dans la liste des attestations de mission.</a>
            </ng-container>
        </div>
        <div *ngIf="showAlerte && modeCreate && this.attestation.status == 'Validée'" class="fr-alert fr-alert--info">
            <ng-container>
                <h3 class="fr-alert__title">Attestation {{ attestation?.id }} Validée avec succès</h3>
                <a>Votre attestation a été validée. Vous pouvez la consulter via la liste des attestations.</a>
            </ng-container> 
        </div>
        <div *ngIf="showAlerte && !modeCreate" class="fr-alert fr-alert--info">
            <ng-container>
                <h3 class="fr-alert__title">Attestation {{ attestation?.id }} a été modifiée avec succès</h3>
                <a>Votre attestation a été modifiée. Vous pouvez la consulter via la liste des attestations.</a>
            </ng-container>
        </div>

        <br>
        <h4>Récapitulatif de l'attestation de mission</h4>

        <div>
            <ul class="fr-btns-group fr-btns-group--left fr-btns-group--inline-lg">
                <li>
                    <button class="fr-btn fr-btn--secondary" (click)="goBack()">
                        Retour à la liste des attestations
                    </button>
                </li>
            </ul>
        </div>


        <div class="wrapper">
            <div class="one box">
                <section id="section-1">
                    <div>
                        <strong>Infomations du justiciable</strong>
                    </div>
                    <div class="fr-separator"></div>
                    <div>
                        {{ attestation?.justiciable?.civilite == 'monsieur' ? 'M' : 'Mme' }} {{ attestation?.justiciable?.prenom | titlecase }} {{ attestation?.justiciable?.nom | uppercase }}<div *ngIf="attestation?.justiciable?.nomUsage" class="sameLine">, {{ attestation?.justiciable?.nomUsage | uppercase }}</div>
                    </div>
                    <div>
                        Né(e) <div *ngIf="attestation?.justiciable?.dateNaissance" class="sameLine">le {{ attestation?.justiciable?.dateNaissance | date:"dd/MM/yy" }} </div><div *ngIf="attestation?.justiciable?.lieuNaissance" class="sameLine">à {{ attestation?.justiciable?.lieuNaissance | titlecase }}, {{ pays[attestation?.justiciable?.paysNaissance] | uppercase }}</div>
                    </div>
                    <div>
                        Domicilié(e)<div *ngIf="attestation?.justiciable?.adresse" class="sameLine"> au {{ attestation?.justiciable?.adresse }}, {{ attestation?.justiciable?.codePostal }} {{ attestation?.justiciable?.ville | titlecase }}, {{ pays[attestation?.justiciable?.pays] | uppercase }} </div>
                    </div>
                </section>
            </div>
            <div class="two box">
                <section id="section-2">
                    <div>
                        <strong>Informations de l'affaire</strong>
                    </div>
                    <div class="fr-separator"></div>
                    <div>
                        {{ attestation?.auxiliaire?.typeAuxiliaire | titlecase}} : {{ attestation?.auxiliaire?.prenom | titlecase }} {{ attestation?.auxiliaire?.nom | uppercase }}<div *ngIf="attestation?.auxiliaire?.typeAuxiliaire=='avocat'" class="sameLine">, {{ attestation?.auxiliaire?.typeAvocat == 'designe' ? 'commis ou désigné d\'office' : 'choisi' }}, inscrit au barreau de {{ attestation?.auxiliaire?.barreau | titlecase }}</div>
                    </div>
                    <div>
                        Affaire  {{ attestation?.affaire?.typeAffaire | lowercase }} n° {{ attestation?.affaire?.numeroAffaire }}, opposé(e) à {{ attestation?.affaire?.partieAdverse | titlecase }}
                    </div>
                    <div *ngIf="attestation?.affaire?.typeAffaire== 'Civile' || attestation?.affaire?.typeAffaire== 'Pénale'" class="sameLine">
                        Au moment de la commission des faits la personne assistée est {{ attestation?.affaire?.personneAssistee == 'mineur' ? 'mineure (m)' : 'majeure (M)' }}
                    </div>
                    <div>
                        Aide juridictionnelle {{ attestation?.affaire?.aideJuridic == 'total' ? 'totale' : 'partielle' }} {{ pourcentageList[attestation?.affaire?.pourcentageAj] }}<div *ngIf="attestation?.affaire?.ajGarantie" class="sameLine">, accordée le {{ attestation?.affaire?.dateBaj | date:"dd/MM/yy" }} pour la demande d'AJ n°{{ attestation?.affaire?.numeroBaj }} (AJ garantie, article 19-1)</div>
                    </div>

                </section>
            </div>
            <div class="three box">
                <section id="section-3">
                    <div>
                        <strong>Mission et majoration(s)</strong>
                    </div>
                    <div class="fr-separator"></div>
                    <div>
                        Mission accomplie le {{ attestation?.mission?.dateAccomplissement | date:"dd/MM/yy" }}
                    </div>
                    <div>
                        <strong>Mission : </strong> {{ libelleList[attestation?.mission?.refMission] }} - {{ attestation?.mission?.nombreUv }} UV
                    </div>
                    <div  *ngFor="let majoration of attestation?.mission?.majorations; let i = index" >
                        <div> <strong>Majoration {{i+1}} : </strong> {{libelleMajList[majoration?.refMajoration]}} - {{majoration.nombreUv}} UV </div>
                    </div>
                    <div>
                        Nombre total d'UV : {{ attestation?.mission?.total }} UV
                    </div>
                    
                </section>

            </div>
        </div>
        <div>
            <section id="section-4">
                <div class="fr-separator"></div>
                <h3 class="fr-accordion__title">
                    <button class="fr-accordion__btn" aria-expanded="true" aria-controls="accordion-validation"><div class="procedure">Procèdures complémentaires <div class="article">Conformément à l'article 92 du décret n°2020-1717 du 28 décembre 2020</div></div></button>
                </h3>
                <div class="fr-separator"></div>
            
                <div class="fr-collapse" id="accordion-validation">
                    <div class="validation">

                    </div>
                
                </div>
            </section>
        </div>
        

        <div>
            <ul class="fr-btns-group fr-btns-group--left fr-btns-group--inline-lg">
                <li>
                    <button class="fr-btn fr-btn--secondary" (click)="goBack()">
                        Retour à la liste des attestations
                    </button>
                </li>
                <!-- <li *ngIf="this.attestation.status != 'Validée'"> -->
                <li>
                    <button class="fr-btn" (click)="validateModal()">
                        Valider
                    </button>
                </li>
            </ul>
        </div>
    </div>
</div>
