import {Injectable} from '@angular/core'

/**
 * Service servant à stocker et récupérer des données dans le localstorage
 */
@Injectable({
  providedIn: 'root',
})
export class StorageService {

  /** retourne la valeur stockée si existante */
  public get(key: string): any {
    const d: any = sessionStorage.getItem(key)

    return !d ? null : JSON.parse(d)
  }

  /** stock la donnée */
  public set(key: string, data: any) {
    sessionStorage.setItem(key, JSON.stringify(data))
  }

}
