<section id="affaire" class="fr-accordion">
    <!-- Bloc Affaire -->
    <h3 class="fr-accordion__title">
        <button class="fr-accordion__btn" aria-expanded="true" aria-controls="accordion-affaire">Affaire</button>
    </h3>

    <div class="fr-collapse" id="accordion-affaire" [formGroup]="parent">
        <div class="fr-grid-row"  formGroupName="affaire">

            <dsfr-input-select ctrlName="typeAffaire" [inputValues]="typeAffaireList" class="fr-form-group fr-col-3 fr-pt-0w fr-pr-6w" label="Type d'affaire" [required]="true" (click)="checkAffaireType()"></dsfr-input-select>

            <dsfr-input-text class="fr-form-group fr-col-3 fr-pt-0w fr-pr-6w" id="numeroAffaire" ctrlName="numeroAffaire" label="Numéro d'affaire" *ngIf="!isNonJuridictionnelle"></dsfr-input-text>
   
            <dsfr-input-text class="fr-form-group fr-col-6 fr-pt-0w fr-pr-6w" id="partieAdverse" ctrlName="partieAdverse" label="Partie adverse"></dsfr-input-text>

            <dsfr-input-radio class="fr-form-group fr-col-6 fr-pt-0w fr-pr-6w" id="personneAssistee" [inputValues]="personneAssistee" ctrlName="personneAssistee" *ngIf="isCivileOrPenale" label="Au moment de la commission des faits la personne assistée est :"></dsfr-input-radio>

        </div>
    </div>


    <h3 class="fr-accordion__title">
        <button class="fr-accordion__btn" aria-expanded="true" aria-controls="accordion-affaire-aj">Aide juridictionnelle</button>
    </h3>

    <div class="fr-collapse" id="accordion-affaire-aj" [formGroup]="parent">
        <div class="fr-grid-row"  formGroupName="affaire">

            <dsfr-input-radio class="fr-form-group fr-col-3 fr-pt-0w fr-pr-3w" id="aideJuridic" [inputValues]="aideJuridic" 
            (returnValue)="returnValues($event)" ctrlName="aideJuridic" label="Type de décision"></dsfr-input-radio>
            

            <dsfr-input-radio *ngIf="typeDecision != 'total' && typeDecision != 'partiel'" ctrlName="pourcentageAj" class="fr-form-group fr-col-4 fr-pt-0w fr-pr-6w" id="pourcentageAj"  [inputValues]="pourcentageAjList" label="Taux d'admission"></dsfr-input-radio>
            <dsfr-input-radio *ngIf="typeDecision == 'total'" [isDisabled]="true" ctrlName="pourcentageAj" class="fr-form-group fr-col-4 fr-pt-0w fr-pr-6w" id="pourcentageAj"  [inputValues]="pourcentageAjTotal" label="Taux d'admission"></dsfr-input-radio>
            <dsfr-input-radio *ngIf="typeDecision == 'partiel'" ctrlName="pourcentageAj" class="fr-form-group fr-col-4 fr-pt-0w fr-pr-6w" id="pourcentageAj"  [inputValues]="pourcentageAjPartiel" label="Taux d'admission"></dsfr-input-radio>
            
            <div class="fr-col-5"></div>

            <dsfr-input-text class="fr-form-group fr-col-3 fr-pt-0w fr-pr-6w" id="numeroBaj" ctrlName="numeroBaj" label="Numéro de la demande"></dsfr-input-text>

            <div class="fr-input-group fr-input-group--{{ dateBajGroup }} fr-col-3 fr-pt-0w fr-pr-6w">
                <label class="fr-label" for="dateBaj">Date de la décision</label>
                <input formControlName="dateBaj" class="fr-input" type="date" id="dateBaj" name="dateBaj">
                <p *ngIf="dateBajGroup == 'error'" id="dateBaj-desc-error" class="fr-error-text">
                    Champ obligatoire
                </p>
            </div>

            <div class="fr-checkbox-group fr-form-group fr-col-6 fr-pt-3w fr-pr-3w">
                <input id="ajGarantie" name="ajGarantie" type="checkbox" formControlName="ajGarantie" aria-describedby="ajGarantie-messages">
                <label class="fr-label" for="ajGarantie">
                    AJ garantie (article 19-1)
                </label>
                <div class="fr-messages-group" id="ajGarantie-messages" aria-live="assertive">
                </div>
            </div>
            
        </div>
    </div>

</section>
