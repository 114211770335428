import {Component, EventEmitter, Input, Output} from '@angular/core'

/** type d'alerte possible */
export type Type = 'error' | 'success' | 'info' | 'warning'

/**
 * Composant Alerte
 */
@Component({
  selector: 'alert',
  templateUrl: './alert.component.html'
})
export class AlertComponent {
  /** titre de l'alerte */
  @Input() title: string = ''
  /** message de l'alerte */
  @Input() msg: string = ''
  /**
   * type d'alerte <br>
   * @see Type
   */
  @Input() type: Type = 'info'
  /** true si le bouton de fermeture doit être affiché */
  @Input() closeBtn: boolean = false
  /** émetteur concernant de fermeture manuel de l'alerte */
  @Output() close = new EventEmitter()

  /** émet true en cas de fermeture manuel de l'alerte */
  onClose() {
    this.close.emit(true)
  }
}
