import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalSimpleComponent } from './components/modal/modal-simple/modal-simple.component';



@NgModule({
  declarations: [
    ModalSimpleComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ModalSimpleComponent
  ]
})
export class ModalModule { }
