import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule , ReactiveFormsModule} from '@angular/forms';

import { PasswordComponent } from './components/password/password/password.component';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  declarations: [PasswordComponent],
  exports: [PasswordComponent]
})
export class PasswordModule { }
