import { Injectable } from '@angular/core';
import { catchError, map, Observable, take, BehaviorSubject, Subject } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { ApiService } from '../api/api.service';
import { RefMission } from '@/app/models/refMission';

/**
 * Service api pour les données du 
 */
@Injectable({
  providedIn: 'root'
})
export class RefMissionService {

  url: string;
  public List$ = new BehaviorSubject<Array<any>>([]);
  public nb$ = new Subject<number>();

  constructor(private apiService: ApiService, public http: HttpClient) {
    this.url = this.apiService.API_URL + '/v1/ref/mission';
  }

  /**
   * Fill in the  list
   */
  public gets() {
    this.getAll().pipe(take(1)).subscribe( data => {
      this.List$.next(data);
      this.nb$.next(data.length);
    });
  }

  /**
   * Retourne toutes les missions activeé du referentiel
   */
  getAll(): Observable<RefMission[]> {
    return this.http.get<any>(this.url, {observe: 'response'}).pipe(
      take(1),
      catchError(this.apiService.handleError),
      map((res: HttpResponse<any>) => res.body)
    )
  }


}
