import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';

import { InputValue } from '../InputValue';
import {RadioComponent} from "./radio.component";

@Component({
  selector: 'emap-radio-dynamique',
  templateUrl: './radio.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class RadioStringComponent extends RadioComponent {

  @Input() override inputValues: InputValue[] = [{value: 'true', label: 'Oui'}, {value: 'false', label: 'Non'}];

}
