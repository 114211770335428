<section id="auxiliaire" class="fr-accordion">
    <!-- Bloc Auxiliaire -->
    <h3 class="fr-accordion__title">
        <button class="fr-accordion__btn" aria-expanded="true" aria-controls="accordion-auxiliaire">Auxiliaire</button>
    </h3>

    <div class="fr-collapse" id="accordion-auxiliaire" [formGroup]="parent">
        <div class="fr-grid-row"  formGroupName="auxiliaire">

            <dsfr-input-select ctrlName="typeAuxiliaire" [inputValues]="typeAuxiliaireList" class="fr-form-group fr-col-4 fr-pt-0w fr-pr-6w" label="Type d'auxiliaire" [required]="true" (click)="checkAuxType()"></dsfr-input-select>

            <div class="fr-col-8"></div>
            
            <dsfr-input-text class="fr-form-group fr-col-4 fr-pt-0w fr-pr-6w" id="prenom" ctrlName="prenom" label="Prénom"></dsfr-input-text>

            <dsfr-input-text class="fr-form-group fr-col-4 fr-pt-0w fr-pr-6w" id="nom" ctrlName="nom" label="Nom"></dsfr-input-text>

            <dsfr-input-select ctrlName="barreau" [inputValues]="barreauList" class="fr-form-group fr-col-4 fr-pt-0w fr-pr-6w" label="Inscrit au barreau de" inputClass="fr-col-12" *ngIf="isAvocat"></dsfr-input-select>
              
            <dsfr-input-radio class="fr-form-group fr-col-12 fr-pt-0w fr-pr-3w" id="typeAvocat" [inputValues]="typeAvocatList" ctrlName="typeAvocat" label="L'avocat est" *ngIf="isAvocat"></dsfr-input-radio>
            
        </div>
    </div>

</section>

