import { Component, Input } from '@angular/core';

@Component({
  selector: 'modal-simple',
  templateUrl: './modal-simple.component.html'
})
export class ModalSimpleComponent {

  @Input('widthClass') widthClass: string = 'fr-col-md-4';
  @Input('modalId') modalId: string = 'modal';

}
