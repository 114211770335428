import { Directive, EventEmitter, Injectable, Input, OnDestroy, OnInit, Output, AfterViewChecked, ChangeDetectorRef, Component } from '@angular/core';
import { AbstractControl, ControlContainer, FormGroup } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';

@Injectable()
@Directive()
export abstract class InputBaseComponent implements OnInit, OnDestroy, AfterViewChecked {

  /** Set le nameInput de l'input */
  @Input() ctrlName: string;
  /** Set la valeur de l'input */
  @Input() value: any;
  /** Set le label de l'input */
  @Input() name: string = '';
  /** Set le label de l'input */
  @Input() label: string = '';
  /** Set l'id html de l'input */
  @Input() id: string = '';
  /** Set class div container */
  @Input() inputClass: string;
  /** Si l'input est désactivé */
  @Input() isDisabled?: boolean = undefined;
  /** Renvoie via un EventEmitter la valeur de l'input en cas de changement */
  @Output() onChange: EventEmitter<any> = new EventEmitter();

  /** Et déclenché à la destruction du composant. Utiliser pour les takeUntil */
  protected _destroy = new Subject()
  /** FormGroup parent */
  formGroup: FormGroup

  /**
   * Retourne l'input du formGroup selon le nameInput
   */
  get input() {
    return this.formGroup?.get(this.ctrlName)
  }

  /**
   * Retourne le "group", morceau de class css dsfr, si l'input est désactivé ou en erreur
   */
  get group() {
    return (this.input?.touched || this.input?.dirty) && this.input?.errors?.['required'] ? 'error' : (this.isDisabled ? 'disabled' : '')
  }

  /** Retourne les validateurs ou un objet vide si l'input est désactivé
   * @return AbstractControl
   */
  get validators(): any {
    return this.isDisabled ? {} : this.input?.validator?.({} as AbstractControl)
  }

  constructor(private readonly changeDetectorRef: ChangeDetectorRef, protected _parentContainer: ControlContainer) {
  }

  ngOnInit(): void {
    this.formGroup = this._parentContainer.control as FormGroup;

    /*
    // si aucun nom set, on construit un nom selon le formGroup parent
    if (this.name == null || this.name == '') {
      this.name = this.buildName()
    }
    // Si aucun id set, on utilise le nom pour valeur
     if (this.id == null || this.id == '') {
      this.id = this.name
    }
    */

    // On initialise la souscription au valueChange pour le onChange emiter
    if (this.formGroup && this.formGroup?.get(this.ctrlName)) {
      const input = this.formGroup.get(this.ctrlName)
      input!.valueChanges.pipe(takeUntil(this._destroy)).subscribe(v => {
        this.onChange.emit(v)
      })
    }
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  /**
   * Construit un nom machine pour l'input en se basant sur le formGroup et le ctrlName
   */
  buildName(): string {
    let name = ''
    if (!this.formGroup || !this.ctrlName) {
      return name
    }
    for (const parent in this.formGroup.controls) {
      if (parent === this.ctrlName) {
        name = this._findName(this.formGroup)
      }
    }

    return name + '-' + this.ctrlName
  }

  /**
   * Construit un nom machine en se basant sur l'arbre du formGroup
   */
  _findName(fb: FormGroup): string {
    const fbParent = fb?.parent
    for (const parentKey in fbParent?.controls) {
      if (fbParent?.get(parentKey) == fb) {
        const r = this._findName(fbParent as FormGroup)
        return r != '' ? r + '-' + parentKey : parentKey
      }
    }

    return ''
  }

  ngOnDestroy() {
    this._destroy.next(1)
  }
}
