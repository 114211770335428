import { Component } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { Title } from '@angular/platform-browser';
import { Router } from "@angular/router";
import { take } from "rxjs";

import { environment  } from '@/environments/environment';
import { LoginData } from "@services/authentification/authentification.service";
import { AuthentificationService } from "@services/authentification/authentification.service";

/**
 * Composant gérant la page de connexion manuelle
 */
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  applicationName: String = '';
  /** message d'erreur en cas d'échec de l'authentification */
  errorMsg = ''
  /** formulaire de connexion */
  formGroup = this.fb.group({
    username: ['', [Validators.required]],
    password: ['', [Validators.required]]
  })

  constructor(
    private readonly title: Title,
    protected fb: FormBuilder,
    public authService: AuthentificationService,
    protected route: Router) {
      this.applicationName = environment.application_name;
  }

  ngOnInit() {
    this.title.setTitle('Connexion - ' + this.applicationName);
  }

  /**
   * Initialise une tentative d'authentification
   */
  login() {
    this.formGroup.markAllAsTouched()
    this.formGroup.updateValueAndValidity()

    if (this.formGroup.invalid) {
      return
    }

     this.authService.post(this.formGroup.getRawValue() as LoginData).subscribe({
      next: _ => this.route.navigate(['/']),
      error: _ => {
        this.errorMsg = 'Identifiants incorrects'
        this.formGroup.valueChanges.pipe(take(1)).subscribe(_ => this.errorMsg = '')
      }
    })
  }
}
