export enum Country {
  AF	 = "AFGHANISTAN",
  ZA	 = "AFRIQUE DU SUD",
  AL	 = "ALBANIE",
  DZ	 = "ALGERIE",
  DE	 = "ALLEMAGNE",
  AD	 = "ANDORRE",
  AO	 = "ANGOLA",
  AG	 = "ANTIGUA-ET-BARBUDA",
  SA	 = "ARABIE SAOUDITE",
  AR	 = "ARGENTINE",
  AM	 = "ARMENIE",
  AU	 = "AUSTRALIE",
  XB	 = "AUTRES PAYS",
  AT	 = "AUTRICHE",
  AZ	 = "AZERBAIDJAN",
  BS	 = "BAHAMAS",
  BH	 = "BAHREIN",
  BD	 = "BANGLADESH",
  BB	 = "BARBADE",
  BE	 = "BELGIQUE",
  BZ	 = "BELIZE",
  BJ	 = "BENIN",
  BT	 = "BHOUTAN",
  BY	 = "BIELORUSSIE",
  MM	 = "BIRMANIE",
  BA	 = "BOSNIE-HERZEGOVINE",
  BW	 = "BOTSWANA",
  BR	 = "BRESIL",
  BN	 = "BRUNEI DARUSSALAM",
  BG	 = "BULGARIE",
  BF	 = "BURKINA FASO",
  BI	 = "BURUNDI",
  KH	 = "CAMBODGE",
  CM	 = "CAMEROUN",
  CA	 = "CANADA",
  CV	 = "CAP-VERT",
  CL	 = "CHILI",
  CN	 = "CHINE",
  CY	 = "CHYPRE",
  CO	 = "COLOMBIE",
  KM	 = "COMORES",
  CG	 = "CONGO",
  CD	 = "CONGO (République Démocratique)",
  KP	 = "COREE (REP. POPULAIR. DEMOCRATI)",
  KR	 = "COREE (REPUBLIQUE DE)",
  CR	 = "COSTA RICA",
  CI	 = "COTE D'IVOIRE",
  HR	 = "CROATIE",
  CU	 = "CUBA",
  DK	 = "DANEMARK",
  DJ	 = "DJIBOUTI",
  DM	 = "DOMINIQUE",
  EG	 = "EGYPTE",
  AE	 = "EMIRATS ARABES UNIS",
  EC	 = "EQUATEUR",
  ES	 = "ESPAGNE",
  EE	 = "ESTONIE",
  ER	 = "ETAT D'ERYTHREE",
  BO	 = "ETAT PLURINATIONAL DE BOLIVIE",
  US	 = "ETATS-UNIS",
  ET	 = "ETHIOPIE" ,
  PG	 = "ET INDT DE PAPOUASIE NLLE GUINEE",
  MK	 = "EX-REP.YOUGOSLAVE DE MACEDOINE",
  RU	 = "FEDERATION DE RUSSIE",
  FJ	 = "FIDJI",
  FI	 = "FINLANDE",
  FR	 = "FRANCE",
  GA	 = "GABON",
  GM	 = "GAMBIE",
  GE	 = "GEORGIE",
  GH	 = "GHANA",
  GR	 = "GRECE",
  GD	 = "GRENADE",
  GT	 = "GUATEMALA",
  GN	 = "GUINEE",
  GW	 = "GUINEE-BISSAO",
  GQ	 = "GUINEE EQUATORIALE",
  GY	 = "GUYANA",
  HT	 = "HAITI",
  HN	 = "HONDURAS",
  HU	 = "HONGRIE",
  XA	 = "INCONNU",
  IN	 = "INDE",
  ID	 = "INDONESIE",
  IR	 = "IRAN (REPUBLIQUE ISLAMIQUE D')",
  IQ	 = "IRAQ",
  IE	 = "IRLANDE",
  IS	 = "ISLANDE",
  IL	 = "ISRAEL",
  IT	 = "ITALIE",
  JM	 = "JAMAIQUE",
  JP	 = "JAPON",
  JO	 = "JORDANIE",
  KZ	 = "KAZAKHSTAN",
  KE	 = "KENYA",
  KG	 = "KIRGHIZISTAN",
  KI	 = "KIRIBATI",
  KW	 = "KOWEIT",
  LA	 = "LAOS (REP. DEMOC. POPULAIRE)",
  LS	 = "LESOTHO",
  LV	 = "LETTONIE",
  LB	 = "LIBAN",
  LR	 = "LIBERIA",
  LY	 = "LIBYE",
  LI	 = "LIECHTENSTEIN",
  LT	 = "LITUANIE",
  LU	 = "LUXEMBOURG",
  MG	 = "MADAGASCAR",
  MY	 = "MALAISIE",
  MW	 = "MALAWI",
  MV	 = "MALDIVES",
  ML	 = "MALI",
  MT	 = "MALTE",
  MA	 = "MAROC",
  MU	 = "MAURICE",
  MR	 = "MAURITANIE",
  XC	 = "MER",
  MX	 = "MEXIQUE",
  MD	 = "MOLDAVIE",
  MC	 = "MONACO",
  MN	 = "MONGOLIE",
  ME	 = "MONTENEGRO",
  MZ	 = "MOZAMBIQUE",
  NA	 = "NAMIBIE",
  NR	 = "NAURU",
  NP	 = "NEPAL",
  NI	 = "NICARAGUA",
  NE	 = "NIGER",
  NG	 = "NIGERIA",
  NO	 = "NORVEGE",
  NZ	 = "NOUVELLE-ZELANDE",
  OM	 = "OMAN",
  UG	 = "OUGANDA",
  UZ	 = "OUZBEKISTAN",
  PK	 = "PAKISTAN",
  PY	 = "PARAGUAY",
  NL	 = "PAYS-BAS",
  PE	 = "PEROU",
  PH	 = "PHILIPPINES",
  PL	 = "POLOGNE",
  PT	 = "PORTUGAL",
  QA	 = "QATAR",
  CF	 = "REPUBLIQUE CENTRAFRICAINE",
  DO	 = "REPUBLIQUE DOMINICAINE",
  SO	 = "REPUBLIQUE FEDERALE DE SOMALIE",
  CZ	 = "REPUBLIQUE TCHEQUE",
  RO	 = "ROUMANIE",
  GB	 = "ROYAUME-UNI",
  RW	 = "RWANDA",
  EH	 = "SAHARA OCCIDENTAL",
  KN	 = "SAINT-CHRISTOPHE-ET-NIEVES",
  LC	 = "SAINTE-LUCIE",
  SM	 = "SAINT-MARIN",
  VC	 = "SAINT-VINCENT-ET-LES-GRENADINES",
  SB	 = "SALOMON (Iles)",
  SV	 = "SALVADOR",
  WS	 = "SAMOA",
  ST	 = "SAO TOME-ET-PRINCIPE",
  SN	 = "SENEGAL",
  RS	 = "SERBIE",
  SC	 = "SEYCHELLES",
  SL	 = "SIERRA LEONE",
  SG	 = "SINGAPOUR",
  SK	 = "SLOVAQUIE",
  SI	 = "SLOVENIE",
  SD	 = "SOUDAN",
  SS	 = "SOUDAN DU SUD",
  LK	 = "SRI LANKA",
  SE	 = "SUEDE",
  CH	 = "SUISSE",
  SR	 = "SURINAME",
  SZ	 = "SWAZILAND",
  SY	 = "SYRIE",
  TJ	 = "TADJIKISTAN",
  TW	 = "TAIWAN",
  TZ	 = "TANZANIE",
  TD	 = "TCHAD",
  PS	 = "TERRITOIRES PALESTINIENS",
  TH	 = "THAILANDE",
  TL	 = "TIMOR ORIENTAL",
  TG	 = "TOGO",
  TO	 = "TONGA",
  TT	 = "TRINITE-ET-TOBAGO",
  TN	 = "TUNISIE",
  TM	 = "TURKMENISTAN",
  TR	 = "TURQUIE",
  TV	 = "TUVALU",
  UA	 = "UKRAINE",
  UY	 = "URUGUAY",
  VU	 = "VANUATU",
  VA	 = "VATICAN (SAINT-SIEGE)",
  VE	 = "VENEZUELA",
  VN	 = "VIET NAM",
  YE	 = "YEMEN",
  ZM	 = "ZAMBIE",
  ZW	 = "ZIMBABWE",
  NT	 = "ZONE NEUTRE" 
  }