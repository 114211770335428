import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule }   from '@angular/forms';

import { CoreModule } from './modules/core/core.module';
import { PagesModule } from './modules/pages/pages.module';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './modules/shared/shared.module';

import { AppComponent } from './app.component';
import { LoginActivate } from './services/LoginActivate.service';
import { AuthentificationService, AuthProviderFactory } from './services/authentification/authentification.service';
import { AuthInterceptor } from './interceptor/auth.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AttestationModule } from './modules/attestation/attestation.module';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    AppRoutingModule,
    CoreModule,
    PagesModule,
    AttestationModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule
  ],
  providers: [
    AuthentificationService,
    LoginActivate,
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {provide: APP_INITIALIZER, useFactory: AuthProviderFactory, deps: [AuthentificationService], multi: true},
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
