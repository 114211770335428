import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";

import { AuthentificationService } from './authentification/authentification.service';

@Injectable({
  providedIn: 'root'
})
export class LoginActivate implements CanActivate {
  constructor(private authService: AuthentificationService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean>|Promise<boolean>|boolean {
    if (!this.authService.isLogged) {
      this.router.navigate(['connexion']);
    }
    return true;
  }
}
