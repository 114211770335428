<div class="fr-input-group fr-input-group--{{group}}" [formGroup]="formGroup">
  <label class="fr-label" for="{{name}}"
         [ngClass]="{'input-required' : validators?.required ?? undefined}">{{label}}</label>
  <ng-container [ngTemplateOutlet]="nameInput ? nameInputTpl : valueTpl"></ng-container>
  <ng-template #nameInputTpl>
    <input class="fr-input"
           [attr.disabled]="isDisabled"
           type="password"
           id="{{id}}"
           name="{{name}}"
           [required]="validators?.required ?? undefined"
           [formControlName]="nameInput">
  </ng-template>
  <ng-template #valueTpl>
    <input class="fr-input"
           [attr.disabled]="isDisabled"
           type="password"
           id="{{id}}"
           name="{{name}}"
           [required]="validators?.required ?? undefined"
           [value]="value ?? ''">
  </ng-template>
  <p *ngIf="group == 'error'" id="{{id}}-desc-error" class="fr-error-text">
    Ce champs est obligatoire
  </p>
</div>

