<dialog aria-labelledby="fr-modal-title" role="dialog" [attr.id]="modalId" class="fr-modal">
    <div class="fr-container fr-container--fluid fr-container-md">
        <div class="fr-grid-row fr-grid-row--center">
            <div class="fr-col-12" [ngClass]="widthClass">
                <div class="fr-modal__body">
                    <div class="fr-modal__header">
                        <button class="fr-link--close fr-link" title="Fermer la fenêtre" [attr.aria-controls]="modalId">Fermer</button>
                    </div>
                    <div class="fr-modal__content">
                        <h2 class="fr-modal__title">
                            <ng-content select="[modalTitle]"></ng-content>
                        </h2>
                    
                        <ng-content select="[modalContent]"></ng-content>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</dialog>
